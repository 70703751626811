/**
 * 配置信息
 */
let tmpHost = location.hostname;
let sReplace = '',
  envStr = '',
  iPort = '',
  url = '',
  ap2Url = '',
  ops2Url = '',
  eUrl = '',
  bInvoiceUrl = '',
  dataCountUrl = '',
  uappwebUrl = '',
  pubAppwebUrl = '',
  eapiUrl = '',
  accessTokenUrl = '',
  ebmsUrl = '',
  wxUrl = '',
  mdapUrl = '',
  staticBaseUrl = '',
  isLocalDev = false,
  uappApiUrl = '',
  hestiaUrl ='',
  eapph5Url='',
  cscUrl = '',
  opsMWeb = '',
  lalafinUrl = '';
const vanSec = document.documentElement.dataset.vanSec || 'stable';
let vanSecReplace = vanSec === 'stable' ? '' : `--${vanSec}`;
console.log(vanSecReplace,'vanSecReplace')
if (/-dev|localhost|^192|^172/.test(tmpHost)) {
  sReplace = '-pre';
  envStr = 'pre';
  vanSecReplace = '';
  isLocalDev = true;
} else if (/-stg/.test(tmpHost)) {
  sReplace = '-stg';
  envStr = 'stg';
} else if (/-pre/.test(tmpHost)) {
  sReplace = '-pre';
  envStr = 'pre';
} else if (/-gra/.test(tmpHost)) {
  sReplace = '-gra';
  envStr = 'gra';
} else {
  vanSecReplace = '';
  sReplace = '';
}
url = `//eappweb${sReplace}.huolala.cn${iPort}`;
ap2Url = `//ap2${sReplace}.huolala.cn${iPort}`;
ops2Url = `//ops2${sReplace}.huolala.cn${iPort}`;
eUrl = `//www${sReplace}.hllep.com${iPort}`;
accessTokenUrl = isLocalDev ? '/accessToken' : `https://eapi.huolala.cn`;
eapiUrl = isLocalDev ? '' : `https://eapi${sReplace}.huolala.cn${iPort}`;
ebmsUrl = isLocalDev ? '' : `https://ebms${sReplace}.huolala.work`;
wxUrl = isLocalDev ? '' : `https://api.weixin.qq.com`;
uappwebUrl = `//uappweb${sReplace}.huolala.cn${iPort}`;
uappApiUrl = isLocalDev ? '/uapp' : `https://uappweb${sReplace}.huolala.cn${iPort}`;
bInvoiceUrl = `//invoice${sReplace}.huolala.cn${iPort}`;
lalafinUrl = sReplace ? `https://lalains-v${sReplace}.huolala.cn` : 'https://www.xiaoyuanins.com';
pubAppwebUrl = `//pub-appweb${sReplace}.huolala.cn${iPort}`;
eapph5Url = `https://eapph5${vanSecReplace}-v${sReplace}.huolala.cn${iPort}`;
mdapUrl = `https://mdap-app.huolala.cn`;
staticBaseUrl = isLocalDev ? location.origin : process.env.BASE_URL;
hestiaUrl = `https://hestia${sReplace}.huolala.cn`; // 活动侧ops-hestia-api的服务域名
cscUrl = `https://csc-fb${sReplace}.huolala.cn`;
opsMWeb =  `https://ops-im-web-v${sReplace}.huolala.cn`;

let bizCome = `//biz-come-h5${sReplace}.lalafin.net${iPort}`; // 金融h5
if (['-stg', '-pre'].includes(sReplace)) {
  bizCome = `//biz-come-h5-v${sReplace}.huolala.cn${iPort}`;
}
const cscFb = `//csc-fb${sReplace}.huolala.cn${iPort}/home2?identity=3&from_id=300&form=epApp`; // 客服中心 - 前面固定。后面带个token

let dataTemp = sReplace;
if (dataTemp === '-pre') {
  dataTemp = '-stg';
}
if (dataTemp === '-gra') {
  dataTemp = '';
}
dataCountUrl = `//das${dataTemp}.huolala.cn${iPort}`;

// 神策埋点
let saTemp = sReplace;
if (saTemp === '-dev' || saTemp === '-stg') {
  saTemp = '-dev';
}
if (saTemp === '-pre') {
  saTemp = '-stg';
}

if (saTemp === '-gra') {
  saTemp = '';
}
let saCountUrl = `https://uba${saTemp}.huolala.cn/sa?project=enterprise`;

//智齿本地化地址
let zcStgUrl =
  'https://csc-online-stg.huolala.cn/chat/h5/v2/index.html?sysnum=425c8f01fca44077821fbefbf2ce5507';
let zcGraUrl =
  'https://csc-online-gra.huolala.cn/chat/h5/v2/index.html?sysnum=c39096aca56b453da9d91e2cb71cbccb';
let zcPrdUrl =
  'https://csc-online.huolala.cn/chat/h5/v2/index.html?sysnum=c39096aca56b453da9d91e2cb71cbccb';
let zcUrl = '';
let landingPagzcUrl = `https://csc-fb${sReplace}.huolala.cn/onlineService/index.html`;
if (/localhost|127.0.0.1|-stg|-pre/.test(tmpHost)) {
  zcUrl = zcStgUrl;
} else if (/-gra/.test(tmpHost)) {
  zcUrl = zcGraUrl;
} else {
  zcUrl = zcPrdUrl;
}
let appDownUrl = `${eapiUrl}/index.php?_m=share&_a=app`;

// 发票特殊字符过滤
let invoiceLimite =
  '§|¨|<|>|&|°|±|·|é|×|à|á|è|ê|ì|í|ò|ó|÷|ú|ā|ē|ě|ī|ń|ň|ō|ū|ǎ|ǐ|ǒ|ǖ|ǜ|ɑ|ɡ|ˇ|ˉ|ˊ|ˋ|˙|' +
  'Σ|Ω|α|ρ|σ|ω|Ё|А|я|ё|‖|‘|’|“|”|‥|…|‰|′|″|‵|※|€|℃|℅|℉|№|℡|Ⅰ|Ⅻ|ⅰ|ⅹ|←|↓|↖|↙|∈|∏|∑|∕|√|∝|∟|∠|∣|∥|∧|∫|' +
  '∮|∴|∷|∽|≈|≌|≒|≠|≡|≤|≧|≮|≯|⊕|⊙|⊥|⊿|⌒|①|⑩|┅|╋|═|╳|▓|■|□|▲|△|▼|▽|◆|◇|○|◎|●|◢|◥|★|☆|☉|♀|♂|〃|' +
  '々|〗|〡|〩|ぁ|ん|゛|ゞ|ァ|ヶ|ヾ|ㄅ|ㄩ|㈠|㈩|㈱|㊣|㎎|㎏|㎜|㎞|㎡|㏄|㏎|㏑|㏒|㏕|龥|||||||||秊|裏|' +
  '隣|兀|﨏|﨑|﨓|﨔|礼|﨟|蘒|﨡|﨣|﨤|﨧|﨩|︰|︳|﹄|﹉|﹏|﹐|﹒|﹔|﹗|﹙|﹦|﹨|﹫|！|～|￠|￥';

// 邮箱后缀验证
let emailSuffix = /(\.com|\.cn|\.net|\.cc|\.org|\.vip|\.xin|\.top|\.club|\.xyz|\.wang|\.win|\.hk|\.tw|\.ltd|\.ai|\.uk|\.co|\.io|\.de|\.us)$/;

//行业类型
let industryArr = [
  '服务业',
  '制造业',
  '批发和零售',
  '物流',
  '房地产',
  '信息和通信',
  '农、牧、林、渔业',
  '艺术、娱乐和文娱',
  '金融和保险',
  '公共服务业',
  '教育',
  '餐饮业',
  '互联网',
  '其他',
];

//员工规模
let staff_sizeArr = [
  '1～10人',
  '11～30人',
  '31～50人',
  '51～100人',
  '101～200人',
  '201～500人',
  '501～1000人',
  '1001～3000人',
  '3000人以上',
];
//用车范围
let car_rangeArr = ['单城市用车', '多城市用车（同城为主）', '多城市用车（跨城为主）'];

//用车频率
let car_frequencyArr = ['日均1单', '日均2～3单', '日均4～10单', '日均10单以上', '不固定'];

//所在部门
let applicant_departArr = [
  '物流管理',
  '产品生产',
  '销售/营销',
  '财务',
  '行政/采购',
  '市场/品牌',
  '其他',
];

//账单状态
let billStatusList = {
  0: '待出账',
  1: '待确认',
  2: '待还款',
  3: '已结清',
  4: '部分还款',
  5: '已逾期',
};

// 神策log打印开关
let isShowSensorsLog = true;
if (['localhost', '192.', '172.'].includes(tmpHost)) {
  isShowSensorsLog = true;
}

//职务
let applicant_roleArr = ['一般员工', '主管/主任', '经理', '总监', '副总经理以上', '其他'];

export default {
  install(Vue) {
    Vue.prototype.$config = {
      envStr,
      sReplace,
      url,
      ap2Url,
      ops2Url,
      eUrl,
      eapiUrl,
      accessTokenUrl,
      ebmsUrl,
      wxUrl,
      bInvoiceUrl,
      pubAppwebUrl,
      dataCountUrl,
      uappwebUrl,
      appDownUrl,
      invoiceLimite,
      emailSuffix,
      saCountUrl,
      zcUrl,
      landingPagzcUrl,
      industryArr,
      staff_sizeArr,
      car_rangeArr,
      car_frequencyArr,
      applicant_departArr,
      applicant_roleArr,
      billStatusList,
      bizCome,
      cscFb,
      mdapUrl,
      staticBaseUrl,
      uappApiUrl,
      hestiaUrl,
      eapph5Url,
      lalafinUrl,
    };
  },
};

export {
  isShowSensorsLog,
  staticBaseUrl,
  eapiUrl,
  appDownUrl,
  cscUrl,
  opsMWeb,
  eapph5Url,
}
