
/**
 * 全局调用action方法
*/
import { isWxa as hasWxa } from '@hll/eapp-utils';
import { EP_H5_OS, EP_HARMONY_OS, epHlightH5Origins } from "@/constant/index";
import MessageListener from "./message-listener";
import { getEpFrom } from "./index";


export function callNativeFunc(params) {
  // eslint-disable-next-line no-console
  console.log('callNative params', params);
  if (window.app && window.app.webcall) {
    window.app.webcall(JSON.stringify(params));
  } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.app) {
    window.webkit.messageHandlers.app.postMessage(JSON.stringify(params));
  }
}

/**
 * 当前页面是否嵌在Iframe里面
*/
const isInIframe = () => {
  const isIframeTag = window?.frameElement?.tagName === 'IFRAME';
  const isSelfWindow = window?.self !== window?.top;
  const hasIframe = window?.frames?.length !== window?.parent?.frames?.length
  return isIframeTag || isSelfWindow || hasIframe;
}

// let inWxa: boolean = false;
// let inFrame: boolean = false;
// let epFrom = '';
// const platFormData = {
//   inWxa: false,
//   inFrame: false,
//   epFrom: ''
// }
// const initPlatformData = async () => {
//   const inWxa = await isWxa();
//   const inFrame = isInIframe();
//   return { inWxa, inFrame }
// }

// const { inWxa, inFrame } = await initPlatformData();

// type CallActionIParams = {
//   /** H5编译场景(嵌在iframe场景)是否调用原生action */
//   callNativeInFrame?: boolean;
//   /** action调用的参数 */
//   actionParams?;
//   /** action调用之前的方法 */
//   beforeFn?: () => void;
//   /** action调用之后的方法 */
//   afterFn?: () => void;
// }
// type FnParams = {
//   /** H5编译场景调用action 包含企业鸿蒙、小b端投放的H5 优先级低于epHarmony和epH5 */
//   appH5?: CallActionIParams;
//   /** H5编译场景【企业鸿蒙】调用action 优先级高于AppH5 */
//   epHarmony?: CallActionIParams;
//   /** H5编译场景【小b端】调用action 优先级高于AppH5 */
//   epH5?: CallActionIParams;
//   /** 企业微信小程序端调用action */
//   wxa?: () => void | CallActionIParams;
//   /** 使用场景： order-share 分享后登录失效，小程序公众号webview内调取eappweb 登录弹窗； */
//   h5?: () => void;
// }

/**
 * ******处理main frame回调回来的数据*******
 * 
 * 在接受到消息后，手动调用挂载在window下面的回调方法，
 * 模拟action调用的一方不在iframe嵌入，app处理完action后回调的场景
*/
const callMainFrameListener = (params) => {
  window.parent.postMessage(params, '*');
  const callbackFnName = params?.callback;
  if (!callbackFnName) {
    return;
  }

  // action
  const listener = new MessageListener();

  // 添加监听器，每次触发后自动移除
  listener.addListener((event) => {
    const { data, origin } = event;
    console.log('收到消息:', data, origin);
    const isTrustOrigin = epHlightH5Origins.includes(origin);
    if (!isTrustOrigin) {
      return;
    }
    // 参考app处理完action后回调全部方法
    window?.[callbackFnName]?.(data);
  });

}

const handleNativeOrFrame = (params) => {
  params?.beforeFn?.();
  // 兼容某些场景不是所有端需要调用，没有action参数则不处理
  if (!params?.actionParams) {
    return;
  }
  if (isInIframe() && !params.callNativeInFrame) {
    console.log('call main frame')
    callMainFrameListener(params?.actionParams)
  } else {
    console.log('call native')
    callNativeFunc(params?.actionParams)
  }
  params?.afterFn?.();
}

/**
 * 全局action处理函数
 * 兼容以下不同平台的action调用
 * 不同平台可能需要调用不同的action
 * 编译H5包含企业鸿蒙和小b 端H5，两个可以单独设置，也可以通过appH5共同设置
 * 1、企业ios
 * 2、企业安卓
 * 3、企业鸿蒙 epHarmony
 * 4、小b端 H5
 * 5、企业微信小程序
 * 
 * main frame: 企业鸿蒙和小b端H5 都是编译H5。
 *  
 * 
 * @param params[callNativeInFrame] H5编译是否调用原生的action；默认false；true通过mainframe message通信。
 * @param params[actionParams] action调用的参数
 * @param fn[appH5] 编译H5的共同处理。
 * @param fn[epHarmony] 企业鸿蒙的处理
 * @param fn[epH5] 小b 端H5处理
 * @param fn[wxa] 企业小程序端处理
 * @param fn[h5] h5端的处理
 * 
 */

export async function invokeAction(params, fn) {
  const getFrom = getEpFrom();
  // 是否是编译H5 包含企业鸿蒙和小b H5
  const callMainFrameH5 = !!(getFrom && window.parent); // 小程序编译投放H5 - iframe场景
  //  是否是企业鸿蒙
  const callEpHarmony = !!(getFrom === EP_HARMONY_OS && window.parent) && !!fn?.epHarmony; // 小程序编译投放H5 - iframe场景
  const callEpH5 = !!(getFrom === EP_H5_OS && window.parent) && !!fn?.epH5; // 小程序编译投放H5 - iframe场景
  const isAndroid = !!(window?.app && window?.app?.webcall);
  const isIOS = !!window.webkit?.messageHandlers?.app;
  const isWxa = await hasWxa();
  const isShareFail = fn && typeof fn === 'object' && !!fn?.h5; // 使用场景： order-share 分享后登录失效，小程序公众号webview内调取eappweb 登录弹窗；
  console.log('invokeAction:::::', callEpHarmony, callEpH5, callMainFrameH5, isAndroid, isIOS, isWxa)
  let callParams = params;
  switch (true) {
    // 企业鸿蒙端
    case callEpHarmony:
      callParams = fn?.epHarmony || fn?.appH5 || params;
      handleNativeOrFrame(callParams);
      break;
    // 小B H5端
    case callEpH5:
      callParams = fn?.epH5 || fn?.appH5 || params;
      handleNativeOrFrame(callParams);
      break;
    // H5端、包含小b H5端和企业鸿蒙端
    case callMainFrameH5:
      callParams = fn?.appH5 || params;
      handleNativeOrFrame(callParams);
      break;
    case isAndroid:
      handleNativeOrFrame(callParams);
      break;
    case isIOS:
      handleNativeOrFrame(callParams);
      break;
    case isWxa:
      if (fn && typeof fn === 'object' && fn?.wxa) {
        fn.wxa();
      } else {
        window.wx.miniProgram.postMessage(params);
      }
      break;
    case isShareFail:
      fn?.h5?.();
      break;
    default:
      console.log('callAction', 'error::callAction 未匹配任一场景');
      break;
  }
}
