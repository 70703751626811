//按需加载模块
const loginSucceed = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/register/login-succeed.vue'));
    },
    'm-register'
  );
};
// 公用模板页面
const registerTemp = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/register/register-temp.vue'));
    },
    'm-register-temp'
  );
};

//前端路由
export default [
  {
    path: '/register-case1',
    component: registerTemp,
    meta: { title: '企业注册', caseName: 'case1' }
  },
  {
    path: '/register-case2',
    component: registerTemp,
    meta: { title: '企业注册', caseName: 'case2' }
  },
  {
    path: '/register-case3',
    component: registerTemp,
    meta: { title: '企业注册', caseName: 'case3' }
  },
  {
    path: '/register',
    name: 'register',
    component: registerTemp,
    meta: { title: '货拉拉企业版', caseName: 'case4' }
  },
  {
    path: '/register-a',
    name: 'register-a',
    component: registerTemp,
    meta: { title: '企业注册', caseName: 'case5' }
  },
  {
    path: '/e-register',
    name: 'e-register',
    component: registerTemp,
    meta: { title: '企业注册', caseName: 'case6' }
  },
  {
    path: '/login-succeed',
    name: 'login-succeed',
    component: loginSucceed,
    meta: { title: '企业注册' }
  }, //注册成功页面
  {
    path: '/register-invoice',
    name: 'register-invoice',
    component: registerTemp,
    meta: { title: '企业注册', caseName: 'case7' }
  },
  {
    path: '/register-invoice01',
    name: 'register-invoice01',
    component: registerTemp,
    meta: { title: '企业注册', caseName: 'case8' }
  },
  {
    path: '/register-scheme',
    name: 'register-scheme',
    component: registerTemp,
    meta: { title: '企业注册', caseName: 'case9' }
  },
  {
    path: '/register-invoice02',
    name: 'register-invoice02',
    component: registerTemp,
    meta: { title: '货拉拉企业版', caseName: 'case10' }
  },
  {
    path: '/register-invoice03',
    name: 'register-invoice03',
    component: registerTemp,
    meta: { title: '企业注册', caseName: 'case11' }
  },
  {
    path: '/register-ticket',
    name: 'register-ticket',
    component: registerTemp,
    meta: { title: '企业注册', caseName: 'case12' }
  },
  {
    path: '/squeeze-page',
    name: 'squeeze-page',
    component: registerTemp,
    meta: { title: '企业注册', caseName: 'case13' }
  },
  {
    path: '/register-login',
    name: 'register-login',
    component: registerTemp,
    meta: { title: '企业注册', caseName: 'case14' }
  },
  {
    path: '/register-invoice04',
    name: 'register-invoice04',
    component: registerTemp,
    meta: { title: '企业注册', caseName: 'case15' }
  },
  {
    path: '/register-case16',
    name: 'register-case16',
    component: registerTemp,
    meta: { title: '企业注册', caseName: 'case16' }
  },
  {
    path: '/register-case17',
    name: 'register-case17',
    component: registerTemp,
    meta: { title: '企业注册', caseName: 'case17' }
  },
  {
    path: '/register-case18',
    name: 'register-case18',
    component: registerTemp,
    meta: { title: '企业注册', caseName: 'case18' }
  }
];
