import Vue from 'vue';
import HllDialog from './index.vue';

const instanceList = [];

export function dialog(options) {
  const DialogConstructor = Vue.extend(HllDialog);
  const instance = new DialogConstructor({
    propsData: options,
  }).$mount();

  instanceList.push(instance);
  document.body.appendChild(instance.$el);

  instanceList.map((instance) => (instance.visible = false));
  Vue.nextTick(() => {
    instance.visible = true;
  });

  return instance;
}
