//state
const state = {
  token: "",
  role: 0,
  uesr_id: 0,
}

// getters
const getters = {
  token: (state) => {
    return state.token
  },
  userId: (state) => {
    return state.uesr_id
  },
  // 是否管理员
  isRole: (state) => {
    if(state.role) {
      return state.role == "1" ? 1 : 0
    }
    return 0
  },

}

// actions
const actions = {}

// mutations
const mutations = {
  // 保存用户信息
  saveUserInfo(state, userInfo) {
    state = Object.assign(state, userInfo)
  },
  // 写入token
  saveToken(state, sVal) {
    if(sVal){
      state.token = sVal
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}


