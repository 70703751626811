// 订单进度
const orderProgress = resolve => {
  require.ensure([],() => {
    resolve(require('../../components/order-progress/index'))
  },'order-progress') // 这个参数一样的模块，会被打包在一个文件中
}

//前端路由
export default [
  { path: '/order-progress',component: orderProgress, meta: { title: '订单进度' } }
]
