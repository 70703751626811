import Vuex from 'vuex'

//引入模块
//模块文件名与相关的vue组件名称保持一致。
import App from './modules/app';
import MIntro from './modules/m-intro'
import register from './modules/register'
import orderPrice from './modules/orderPrice'
import invoice from './modules/invoice'
import keepAlive from './modules/keep-alive'
// 企业信息
import information from './modules/e-information';
// 用户信息
import UserInfo from './modules/user'
// 货运资质白名单
import freightQualificationCertification from './modules/freight-qualification-certification'
// 充值中心
import recharge from './modules/recharge'
// 电子回单
import receipt from './modules/electronic-receipt'
// coupedata
import coupeCommon from './modules/coupe-common';

export default {
  install(Vue) {
    //使用Vuex插件
    Vue.use(Vuex)

    //创建store实例
    this.store = new Vuex.Store({
      modules: {
        MIntro,
        register,
        orderPrice,
        invoice,
        keepAlive,
        information,
        UserInfo,
        freightQualificationCertification,
        recharge,
        App,
        receipt,
        coupeCommon,
      }
    })
  },
  store: null//把store实例传递出去，用于Vue实例化方法的参数
}
