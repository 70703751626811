import { VehicleTypeKV } from '@/constant/vechicle-type-dict';
import { getQueryString } from '@hll/eapp-utils';

// 全局设置跑腿/司机角色文案
const driverRoleText = {
  install() {
    const vehicleType = getQueryString('vehicle_type');
    Vue.prototype.$driverRoleText = vehicleType === VehicleTypeKV.WHEEL2 ? '骑手' : '司机';
    Vue.prototype.$getDriverRoleText = function(vehicleType) {
      return vehicleType === VehicleTypeKV.WHEEL2 ? '骑手' : '司机';
    }
  }
}

export default driverRoleText;
