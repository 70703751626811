/*
 * @Author: sybil.chen
 * @Date: 2024-08-19 17:00:15
 * @LastEditTime: 2024-08-27 15:09:08
 * @LastEditors: sybil.chen
 * @Description: 兼容多端 aciton 方法封装
 * 1、企业微信小程序
 * 2、企业ios
 * 3、企业安卓
 * 4、企业鸿蒙
 * 5、小b H5
 */
import { invokeAction } from "./action-handler";
import { myConsole } from '@/services/watch-dog';


/**
 * 退出登录逻辑：
 * 小B app 关闭webview
 * 企业ios、安卓、鸿蒙 app 退出登录
 * 企业小程序 跳转到登录页面
*/
export const toLoginPageAction = (wxLoginFrom) => {

  // 企业APP
  return invokeAction(
    {
      actionParams: {
        action: 'openNativePage',
        type: 1,
      },
      callNativeInFrame: true,
    },
    // 企业小程序
    {
      wxa: () => {
        myConsole.log('调用小程序登录');
        wx.miniProgram.navigateTo({
          url: `/pages/login/index?from=${wxLoginFrom || 'h5-login'}`,
        });
      },
      epH5: {
        callNativeInFrame: true,
        actionParams: {
          action: "closeWebView"
        }
      },
    },
  );

}


/**
 * 企业IOS\Android 企业鸿蒙 重启App
 * 小b H5 - 关闭webview
 * 企业小程序 重启小程序到index页面 
*/
export const startAppAction = ({ isRefresh }) => {
  return invokeAction({
    actionParams: {
      action: 'startUsing',
      isRefresh,
    },
    callNativeInFrame: true,
  }, {
    epH5: {
      callNativeInFrame: true,
      actionParams: {
        action: "closeWebView"
      }
    },
    wxa: () => {
      wx.miniProgram.reLaunch({
        url: '/pages/index/index',
      })
    },
  })
}


/**
 * 唤起拨打电话
 * 企业IOS\Android  企业鸿蒙、小b H5 通过action调用唤起
 * 企业小程序 通过location.href调用 
*/
export const callPhoneAction = (phoneNo) => {
  return invokeAction({
    actionParams: {
      action: 'callPhone',
      phone_no: phoneNo,
    },
    callNativeInFrame: true,
  },
    {
      wxa: () => {
        window.location.href = `tel:${phoneNo}`;
      }
    })
}

// 设置导航栏标题recharge
export const setTitleAction = (title) => {
  return invokeAction({
    actionParams: {
      action: "setTitle",
      title
    },
    callNativeInFrame: true,
  })
}

// 跳转流水记录
export const jumpFlowRecord = () => {
  invokeAction({
    actionParams: {
      action: 'jumpAppInterface', type: 12
    }
  }, {
    wxa: () => {
      wx.miniProgram.navigateTo({ url: '/pages/personal-center/flow-record/index' });
    },
    appH5: {
      actionParams: {
        action: 'jumpPage',
        url: '/pages/personal-center/flow-record/index',
      }
    }
  })
}

// 跳转系统权限设置页面
export const openSystemSettingPageAction = () => {
  invokeAction({
    actionParams: {
      action: 'openSystemSettingPage',
    }
  })
}

// 获取隐私权限是否授权列表
export const getPermissionListAction = (callback) => {
  return invokeAction({
    actionParams: {
      action: 'getPermissionList',
      callback,
    }
  })
}

export const jumpBackAction = () => {
  return invokeAction(
    {
      actionParams: {
        action: 'closeWebView',
      },
    },
    {
      wxa: () => {
        wx.miniProgram.navigateBack();
      },
      appH5: {
        actionParams: {
          action: 'jumpBack',
        },
      },
    },
  );
}

export const homePageAction = (params) => {
  return invokeAction(
    {
      actionParams: {
        action: 'jumpPage',
        url: `/pages/home/index${params}`,
      },
    },
    {
      wxa: () => {
        wx.miniProgram.navigateTo({ url: `/pages/home/index${params}` });
      },
    },
  );
}