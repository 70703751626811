// 添加报价
const addQuotation = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/add-quotation/index.vue'));
    },
    'addQuotation',
  );
};

// 货物类型
const goodsType = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/add-quotation/goods-type.vue'));
    },
    'goodsType',
  );
};

// 前端路由
export default [
  {
    path: '/add-quotation',
    name: 'addQuotation',
    component: addQuotation,
    meta: { title: '添加报价' },
  },
  {
    path: '/goods-type',
    name: 'goodsType',
    component: goodsType,
    meta: { title: '货物类型' },
  },
];
