// 企业关联
const enterpriseAssociation = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/ep-association/detail.vue'));
    },
    'epAssociation',
  );
};
export default [
  {
    path: '/enterprise/association',
    name: 'enterpriseAssociation',
    component: enterpriseAssociation,
    meta: { title: '企业关联' },
  },
];
