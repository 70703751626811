<template>
  <transition name="hll-dialog">
    <div v-if="$visible" class="hll-dialog">
      <div class="hll-dialog-mask"></div>
      <div class="hll-dialog-box-wrap">
        <div class="hll-dialog-box" :style="dialogStyle">
          <slot></slot>
          <h2 class="title" v-if="title">{{ title }}</h2>
          <slot name="title"></slot>
          <div :class="['content', $contentAlign]" v-if="content" v-html="content"></div>
          <slot name="content"></slot>
          <div class="footer">
            <hll-button v-if="cancelVisible" :type="cancelType" @click="fnCancel">
              {{ cancelText }}
            </hll-button>
            <hll-button v-if="okVisible" :type="okType" @click="fnOk">
              {{ okText }}
            </hll-button>
          </div>
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import HllButton from '../hll-button/index.vue';

export default {
  name: 'HllDialog',
  components: {
    HllButton,
  },
  model: {
    prop: 'visible',
    event: 'input',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    contentAlign: {
      type: String,
    },
    okVisible: {
      type: Boolean,
      default: true,
    },
    okType: {
      type: String,
      default: 'primary',
    },
    okText: {
      type: String,
      default: '确认',
    },
    onOk: {
      type: Function,
    },
    cancelVisible: {
      type: Boolean,
      default: true,
    },
    cancelType: {
      type: String,
      default: 'plain',
    },
    cancelText: {
      type: String,
      default: '取消',
    },
    onCancel: {
      type: Function,
    },
    dialogStyle: {
      type: Object,
      default: (()=>{}),
    },
  },
  computed: {
    $visible: {
      get() {
        return this.visible;
      },
      set(newVal) {
        this.$emit('input', newVal);
      },
    },
    $contentAlign() {
      if (this.contentAlign) {
        return this.contentAlign;
      } else {
        return this.content.length > 15 ? 'left' : 'center';
      }
    },
  },
  watch: {
    $visible(visible) {
      if (visible) {
        this.bgColor = document.body.style.backgroundColor;
      }

      document.body.style.backgroundColor = visible ? 'rgba(15, 18, 41, 0.75)' : this.bgColor;
    },
  },
  methods: {
    close() {
      this.visible = false;
      this.$visible = false;
    },
    fnCancel() {
      if (typeof this.onCancel === 'function') {
        this.onCancel();
      } else {
        this.close();
      }
      this.$emit('cancel');
    },
    fnOk() {
      if (typeof this.onOk === 'function') {
        this.onOk();
      } else {
        this.close();
      }
      this.$emit('ok');
    },
  },
};
</script>

<style lang="scss" scoped>
.hll-dialog {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  height: calc(100vh + constant(safe-area-inset-bottom));
  height: calc(100vh + env(safe-area-inset-bottom));
  padding-bottom: 0;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hll-dialog-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  height: calc(100vh + constant(safe-area-inset-bottom));
  height: calc(100vh + env(safe-area-inset-bottom));
  padding-bottom: 0;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  background: rgba(15, 18, 41, 0.75);
}

.hll-dialog-box-wrap {
  position: fixed;
  left: 24px;
  right: 24px;
  padding-bottom: 60px;
}

.hll-dialog-box {
  padding: 16px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.05), 0px 3px 6px 0px rgba(0, 0, 0, 0.05),
    0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.title {
  padding-top: 8px;
  font-size: 20px;
  font-weight: 600;
  text-align: CENTER;
  line-height: 28px;
  color: #0f1229;
}

.content {
  padding: 12px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #0f1229;
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
}

.footer {
  padding-top: 12px;
  display: flex;
}

.hll-button {
  flex: 1;
}

.hll-button + .hll-button {
  margin-left: 12px;
}

.hll-dialog-enter-active,
.hll-dialog-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  .hll-dialog-mask {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .hll-dialog-box {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  }
}

.hll-dialog-enter,
.hll-dialog-leave-to {
  .hll-dialog-mask {
    opacity: 0;
  }
  .hll-dialog-box {
    opacity: 0;
    transform: scale(0, 0);
  }
}

.hll-dialog-enter-to,
.hll-dialog-leave {
  .hll-dialog-mask {
    opacity: 1;
  }
  .hll-dialog-box {
    opacity: 1;
    transform: scale(1, 1);
  }
}
</style>
