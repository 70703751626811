//按需加载模块
const taxAmount = resolve => {
  require.ensure([],() => {
    resolve(require('../../components/tax/tax-amount'))
  },'tax-amount')//这个参数一样的模块，会被打包在一个文件中
}

//前端路由
export default [
  { path: '/tax-amount',component: taxAmount,meta: { title: '税额' } }
]
