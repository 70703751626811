import { asyncLoadBaiduMap } from '@/assets/js/baiduMapApi';
import { BMapPromiseEvent } from '@/services/promise-event';
export default {
  install(Vue) {
    Vue.mixin({
      data() {
        return {
          baiduMapLoading: false,
        };
      },
      methods: {
        loadBaiduMap(loading) {
          this.baiduMapLoading = true;
          return new Promise((resolve, reject) => {
            if (loading) {
              this.$hll.showLoading({
                cover: 1,
              });
            }
            asyncLoadBaiduMap()
              .then(() => {
                this.baiduMapLoading = false;
                if (loading) {
                  this.$hll.hideLoading();
                }
                BMapPromiseEvent.resolver();
                resolve();
              })
              .catch(() => {
                this.baiduMapLoading = false;
                if (loading) {
                  this.$hll.hideLoading();
                }
                BMapPromiseEvent.rejecter();
                reject();
              });
          });
        },
      },
    });
  },
};
