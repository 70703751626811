import { definedDicts } from '@hll/full';

// vehicle_type  车型类别：1-两轮 2-三轮 3-三米八 4-轿车 5-线上货运四轮
export const VehicleTypeDict = definedDicts([
  {
    key: 'WHEEL2',
    value: '1',
    text: '两轮',
  },
  {
    key: 'WHEEL3',
    value: '2',
    text: '三轮',
  },
  {
    key: 'METERS38',
    value: '3',
    text: '三米八',
  },
  {
    key: 'WHEEL4SEDAN',
    value: '4',
    text: '轿车',
  },
  {
    key: 'WHEEL4FREIGHT',
    value: '5',
    text: '货运四轮',
  },
]);


/**
 * 导出 { key: value } 格式，方便使用
 * example：VehicleTypeKV.WHEEL4SEDAN
 */
export const VehicleTypeKV = VehicleTypeDict.kv;
export const VehicleTypeVK = VehicleTypeDict.vk;

