//state
const state = {
	selectCity:null,
	cityList:[],
  curProvince:null,
  client_type:null,
  client:null,
  loginInfo: null

}

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  //设置城市列表
  setSelectCity(state, selectCity) {
    state.selectCity = selectCity
  },
  setCityList(state, cityList){
  	state.cityList = cityList
  },
  setCurProvince(state, curProvince){
  	state.curProvince = curProvince
  },
  //设置client_type状态
  setclient_type(state, client_type) {
    state.client_type = client_type
  },
  //设置client_type状态
  setclient(state, client) {
    state.client = client
  },
  //设置注册并登陆回传信息状态
  setLoginInfo(state, data) {
    state.loginInfo = data
  }
}

export default {//暴露出去
  state,
  getters,
  actions,
  mutations
}


