const ePartner = resolve => {
  require.ensure([], () => { resolve(require('../../components/m-intro/e-partner.vue')) }, 'm-intro')
}

//前端路由
export default [
  //{path: '/', component: Home, meta: {title: '企业版介绍'}},
  //{path: '/m-intro', component: Home, meta: {title: '企业版介绍'}},
  //{path: '/', redirect: '/e-intro'},
  //{path: '/m-intro', redirect: '/e-intro'},
  //{path: '/e-intro', component: eIntro, meta: {title: '货拉拉企业版'}},
  //{path: '/e-intro2', component: eIntro, meta: {title: '货拉拉企业版', demand: 'market'}},
  //{path: '/e-partner/:partner', component: ePartner, meta: {title: '货拉拉企业版'}},
  {path: '/', redirect: '/intro'},
  {path: '/m-intro', redirect: '/intro'},
  {path: '/e-intro', redirect: '/intro'},
  {path: '/e-intro2', redirect: '/ad'},
  {path: '/e-partner/:partner', component: ePartner, meta: {title: '货拉拉企业版'}},
]
