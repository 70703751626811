//按需加载模块
const electronicReceiptSign = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/electronic-receipt/sign'));
    },
    'electronic-receipt-sign',
  ); //这个参数一样的模块，会被打包在一个文件中
};

// pdf预览
const electronicReceiptPdf = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/electronic-receipt/pdf'));
    },
    'electronic-receipt-pdf',
  ); //这个参数一样的模块，会被打包在一个文件中
};

// 电子回单列表页
const electronicReceiptList = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/electronic-receipt/list'));
    },
    'electronic-receipt-list',
  ); //这个参数一样的模块，会被打包在一个文件中
};

// 电子回单详情页
const electronicReceiptDetail = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/electronic-receipt/detail'));
    },
    'electronic-receipt-detail',
  ); //这个参数一样的模块，会被打包在一个文件中
};

//前端路由
export default [
  {
    path: '/electronic-receipt/sign',
    component: electronicReceiptSign,
    meta: { title: '写入签名' },
  },
  { path: '/electronic-receipt/pdf', component: electronicReceiptPdf, meta: { title: '文件预览' } },
  {
    path: '/electronic-receipt/list',
    component: electronicReceiptList,
    meta: { title: '回单签收' },
  },
  {
    path: '/electronic-receipt/detail',
    component: electronicReceiptDetail,
    meta: { title: '电子回单' },
  },
];
