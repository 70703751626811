// 静态页面 - 按需加载模块
// 条款
const recharge = resolve => {
  require.ensure([], () => {
      resolve(require('../../components/recharge/recharge.vue'))
  }, 'recharge')
};
const rechargeSuccess = resolve => {
  require.ensure([], () => {
      resolve(require('../../components/recharge/b-test/success.vue'))
  }, 'recharge')
};
// 前端路由
export default [
  {
    path: '/recharge',
    name: 'recharge',
    component: recharge,
    meta: {title: '充值中心'}
  },
  {
    path: '/recharge-success',
    name: 'rechargeSuccess',
    component: rechargeSuccess,
    meta: {title: '充值中心'}
  }
]
  
