// 用户指南
const userGuide = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/user-guide/index'))
  }, 'user-guide')
};
// 首页介绍
const frontPage = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/user-guide/front-page'))
  }, 'front-page')
};

// 前端路由
export default [
  {
    path: '/user-guide',
    component: userGuide,
    meta: {title: '用户指南'}
  },
  {
    path: '/user-guide/front-page',
    name: 'frontPage',
    component: frontPage,
    meta: {title: '首页介绍'}
  },
]
