//state
const state = {
  eInformation: null
};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  //设置企业信息
  setEpInfo(state, eInformation) {
    state.eInformation = eInformation
  },
  // 管理员修改个人信息成功后更新store信息,只修改部分信息
  setAuthInfo(state, authInfo) {
    state.eInformation.staff_name = authInfo.name;
    state.eInformation.staff_no = authInfo.staffNo;
    state.eInformation.depart_id = authInfo.departId;
    state.eInformation.depart_name = authInfo.departName;
    state.eInformation.staff_email = authInfo.email;
    state.eInformation.city_name = authInfo.city;
    state.eInformation = Object.assign({}, state.eInformation); // 对整个重新赋值，使vue监听到此次更新
  },
  // 管理员修改部门信息成功后更新store信息,只修改部门信息
  setAuthDepart(state, departName) {
    state.eInformation.depart_name = departName;
    state.eInformation = Object.assign({}, state.eInformation); // 对整个重新赋值，使vue监听到此次更新
  }
};

export default {
  state,
  getters,
  actions,
  mutations
}


