/**
 * 定义vue指令
 */
export default {
  install(Vue) {
    //使用v-backgroundColor ，设置页面背景颜色
    Vue.directive('backgroundColor', {
      inserted(el, binding) {
        document.documentElement.style.backgroundColor = binding.value
      },
      unbind(el, binding) {
        document.documentElement.style.backgroundColor = ''
      },
    })
    //使用v-background ，设置页面背景颜色, 用于覆盖顽固
    Vue.directive('background', {
      inserted(el, binding) {
        document.documentElement.style.background = binding.value
      },
      unbind(el, binding) {
        document.documentElement.style.background = ''
      },
    })

    //使用v-title 设置页面title
    Vue.directive('title', {
      inserted: function (el, binding) {
        document.title = binding.value
      },
      update: function (el, binding) {
        document.title = binding.value
      }
    })

    // 整数
    Vue.directive('inputInt', {
      bind(el, binding, vnode) {
        let input = vnode.elm;
        input.addEventListener('compositionstart', () => {
          vnode.inputLocking = true
        })
        input.addEventListener('compositionend', () => {
          vnode.inputLocking = false
          input.dispatchEvent(new Event('input'))
        })
        input.addEventListener('input', () => {
          if (vnode.inputLocking) {
            return;
          }
          let oldValue = input.value;
          let newValue = input.value.replace(/[^\d]/g, '');
          if (newValue) {
            switch (binding.value) {
              case 'zeroBefore':
                break; // 数字随意输，不做处理，如 000013
              case 'zeroCan':
                newValue = Number(newValue).toString(); // 去掉开头0 正整数 + 0
                break;
              default:
                newValue = newValue.replace(/^\b(0+)/gi, ''); // （默认）去掉开头0 正整数
            }
          }
          // 判断是否需要更新，避免进入死循环
          if (newValue !== oldValue) {
            input.value = newValue
            input.dispatchEvent(new Event('input')) // 通知v-model更新 vue底层双向绑定实现的原理是基于监听input事件
          }
        })
      }
    })


    //输入限制
    Vue.directive('inputLimit',{
      bind(el,binding,vnode){
        let input = vnode.elm;
        input.addEventListener('compositionstart',() =>{
          vnode.inputLocking = true;
        })
        input.addEventListener('compositionend',()=>{
          vnode.inputLocking = false;
          input.dispatchEvent(new Event('input'))
        })
        input.addEventListener('input',()=>{
          if(vnode.inputLocking){
            return;
          }
          let oldValue = input.value;
          let newValue = input.value;
          
          if(newValue){
            switch(binding.value){
              case 'noNumber':
                newValue = newValue.replace(/[\d]/g,'')
                break;
              case 'letterAndNumber': 
                newValue = newValue.replace(/[\W]/g,'')
                break;
              default:
                newValue = newValue.replace(/[\d]/g,'')//默认去除数字
                break;
            }
          }
          if(newValue !== oldValue){
            input.value = newValue
            input.dispatchEvent(new Event('input'))
          }
        })
      }
    })

    // 保留两位小数 浮点数 （目前只支持1或2位小数 正则变量不会 待扩展）
    Vue.directive('inputFloat', {
      bind(el, binding, vnode) {
        let input = vnode.elm;
        input.addEventListener('compositionstart', () => {
          vnode.inputLocking = true
        })
        input.addEventListener('compositionend', () => {
          vnode.inputLocking = false
          input.dispatchEvent(new Event('input'))
        })
        input.addEventListener('input', () => {
          if (vnode.inputLocking) {
            return;
          }
          let oldValue = input.value;
          let newValue = input.value;
          newValue = newValue.replace(/[^\d.]/g, '');
          newValue = newValue.replace(/^\./g, '');
          newValue = newValue.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
          if (binding.value === '1' || binding.value === 1) {
            newValue = newValue.replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3')
          } else {
            newValue = newValue.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
          }
          if (newValue) {
            let arr = newValue.split('.')
            newValue = Number(arr[0]) + (arr[1] === undefined ? '' : '.' + arr[1]) // 去掉开头多余的0
          }
          // 判断是否需要更新，避免进入死循环
          if (newValue !== oldValue) {
            input.value = newValue
            input.dispatchEvent(new Event('input')) // 通知v-model更新
          }
        })
        // input 事件无法处理小数点后面全是零的情况 因为无法确定用户输入的0是否真的应该清除，如3.02。放在blur中去处理
        input.addEventListener('blur', () => {
          let oldValue = input.value;
          let newValue = input.value;
          if (newValue) {
            newValue = Number(newValue).toString()
          }
          // 判断是否需要更新，避免进入死循环
          if (newValue !== oldValue) {
            input.value = newValue
            input.dispatchEvent(new Event('input')) // 通知v-model更新
          }
        })
      }
    })
    //输入限制
    Vue.directive('inputLimit', {
      bind(el, binding, vnode) {
        let input = vnode.elm;
        input.addEventListener('compositionstart', () => {
          vnode.inputLocking = true;
        })
        input.addEventListener('compositionend', () => {
          vnode.inputLocking = false;
          input.dispatchEvent(new Event('input'))
        })
        input.addEventListener('input', () => {
          if (vnode.inputLocking) {
            return;
          }
          let oldValue = input.value;
          let newValue = input.value;

          if (newValue) {
            switch (binding.value) {
              case 'noNumber':
                newValue = newValue.replace(/[\d]/g, '')
                break;
              case 'letterAndNumber':
                newValue = newValue.replace(/[\W]/g, '')
                break;
              default:
                newValue = newValue.replace(/[\d]/g, '') //默认去除数字
                break;
            }
          }
          if (newValue !== oldValue) {
            input.value = newValue
            input.dispatchEvent(new Event('input'))
          }
        })
      }
    })



    // 过滤集
    Vue.directive('inputFilter', {
      bind(el, binding, vnode) {
        let input = vnode.elm;
        input.addEventListener('compositionstart', () => {
          vnode.inputLocking = true
        })
        input.addEventListener('compositionend', () => {
          vnode.inputLocking = false
          input.dispatchEvent(new Event('input'))
        })
        input.addEventListener('input', () => {
          if (vnode.inputLocking) {
            return;
          }
          let oldValue = input.value;
          let newValue = input.value;
          const { arg: type } = binding; // 校验类型
          const oFilter = {
            phone: () => { // 1开头的数字
              newValue = newValue.replace(/^(?!1).?|\D/g, '');
            },
            space: () => { // 空格
              newValue = newValue.replace(/\s+/, '');
            },
            code: () => {
              newValue = newValue.replace(/[^a-zA-z0-9]/, '');
            },
          }

          if (newValue) {
            oFilter[type]();
          }

          // 判断是否需要更新，避免进入死循环
          if (newValue !== oldValue) {
            input.value = newValue
            input.dispatchEvent(new Event('input')) // 通知v-model更新 vue底层双向绑定实现的原理是基于监听input事件
          }
        })
      }
    })

  }
}