import MockAdapter from 'axios-mock-adapter';

//导入假数据
import cityList from './data/cityList'

export default function (axios) {
  const mock = new MockAdapter(axios);

  //拦截请求
  mock.onGet('http://ap2.huolala.cn/index.php?_m=driver_register&_a=get_city_list').reply(200, cityList);
};


