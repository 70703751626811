const goodsSafeDetail = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/goods-safe/detail.vue'));
    },
    'goodsSafe'
  );
};
const goodsSafeExplain = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/goods-safe/explain.vue'));
    },
    'goodsSafe'
  );
};

// 前端路由
export default [
  {
    path: '/goods-safe/detail',
    name: 'goodsSafeDetail',
    component: goodsSafeDetail,
    meta: {
      title: '货物保障'
    }
  },
  {
    path: '/goods-safe/explain',
    name: 'goodsSafeExplain',
    component: goodsSafeExplain,
    meta: {
      title: '保障金额'
    }
  }
];
