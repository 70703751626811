// 订单分享 - 按需加载模块
// 订单详情
const newOrderDetail = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/order-share/new-detail.vue'));
    },
    'order-share',
  );
};
const orderMove = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/order-share/move.vue'));
    },
    'order-share',
  );
};
const orderEnd = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/order-share/end.vue'));
    },
    'order-share',
  );
};
const orderPrice = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/order-share/price.vue'));
    },
    'order-share',
  );
};
// 前端路由
export default [
  {
    path: '/order-share/new-detail',
    component: newOrderDetail,
    meta: {
      title: '订单详情',
    },
  },
  {
    path: '/order-share/move',
    component: orderMove,
    meta: {
      title: '货拉拉订单',
    },
  },
  {
    path: '/order-share/end',
    component: orderEnd,
    meta: {
      title: '',
    },
  },
  {
    path: '/order-share/price',
    component: orderPrice,
    meta: {
      title: '价格明细',
    },
  },
  {
    path: '/order-share/',
    component: orderEnd,
    meta: {
      title: '',
    },
  },
];
