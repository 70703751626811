export const createPromiseEventBus = () => {
  let resolver
  let rejecter
  let resolved = false
  const promise = new Promise((resolve, reject) => {
    resolver = (data) => {
      resolved = true
      resolve(data)
    }
    rejecter = (data) => {
      reject(data)
    }
  })
  return {
    resolver,
    rejecter,
    promise,
    resolved,
  }
}
