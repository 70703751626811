//state
const state = {
  eReceipt: null,
  currentPdf: null,
};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  updateEReceipt(state, payload) {
    state.eReceipt = payload;
  },
  updatePdfUrl(state, item) {
    state.currentPdf = item;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
