//state
const state = {
  invoiceQualification: 1, // 开票资质：1-电子，2-纸质
  defaultAddress: null, // 发票默认地址
  originOrderList: [], // 按行程可开票列表
  checkedOrderList: [], // 选择的可开票列表
}

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  setInvoiceQualification(state, value) {
    state.invoiceQualification = value
  },
  setDefaultAddress(state, address) {
    state.defaultAddress = address
  },
  setOriginOrderList(state, list) {
    state.originOrderList = list
  },
  setCheckedOrderList(state, list) {
    state.checkedOrderList = list
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}


