//按需加载模块
const DriverRecruit = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/driver-recruit/recruit-list.vue'))
  }, 'driver-recruit')
}

//前端路由
export default [
  {path: '/driver-recruit/recruit-list', component: DriverRecruit, meta: {title: '司机招募'}},
]
