//按需加载模块
const activity = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/activity-page/activity.vue'));
    },
    'activity'
  ); //这个参数一样的模块，会被打包在一个文件中
};
const epCharge = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/activity-page/ep-charge/ep-charge.vue'));
    },
    'ep-charge'
  ); //这个参数一样的模块，会被打包在一个文件中
};
const epLp = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/activity-page/ep-lp/ep-lp.vue'));
    },
    'ep-lp'
  ); //这个参数一样的模块，会被打包在一个文件中
};

const activity071101 = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/activity-page/07-11/activity07-1101.vue'));
    },
    'activity'
  ); //这个参数一样的模块，会被打包在一个文件中
};

const activity0823 = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/activity-page/08-23/activity08-23.vue'));
    },
    'activity'
  ); //这个参数一样的模块，会被打包在一个文件中
};

const mpIndex = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/mp/index.vue'));
    },
    'mp'
  ); //这个参数一样的模块，会被打包在一个文件中
};
const mpInfoFill = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/mp/info-fill.vue'));
    },
    'mp'
  ); //这个参数一样的模块，会被打包在一个文件中
};
const mpSuccess = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/mp/success.vue'));
    },
    'mp'
  ); //这个参数一样的模块，会被打包在一个文件中
};
const shareToNew = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('@/components/activity-page/share-to-new/index.vue'));
    },
    'activity'
  );
};
//前端路由
export default [
  // 静态页面 - 通过eops配置生成静态页面的模板 - 勿删
  { path: '/activity-img/:color/:img', component: activity },
  { path: '/activity/ep-charge', component: epCharge, meta: { title: '特惠充值日' } },
  { path: '/activity/ep-lp', component: epLp, meta: { title: '货拉拉企业版' } },
  {
    path: '/activity/07-11/activity071101',
    component: activity071101,
    meta: { title: '货拉拉企业版新粉福利' }
  },
  {
    path: '/activity/08-23/activity0823',
    component: activity0823,
    meta: { title: '20元券连抢两天！限量10000份' }
  },
  { path: '/activity/mp/index', component: mpIndex, meta: { title: '定制城配' } },
  { path: '/activity/mp/info-fill', component: mpInfoFill, meta: { title: '信息填写' } },
  {
    path: '/activity/mp/info-fill-pop',
    component: mpInfoFill,
    meta: { title: '信息填写', use: 'popTest' }
  }, // 小B端弹窗测试用
  { path: '/activity/mp/success', component: mpSuccess, meta: { title: '信息填写' } },
  { path: '/activity/share-to-new', component: shareToNew, meta: { title: '送您“货”礼' } }
];
