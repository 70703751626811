import watchdog from '@hll/watch-dog-jsutil';
import { VanEnv } from '@/tools/env';
import { getQueryString } from '@hll/eapp-utils';


// 初始化watchdog
export function initWatchdog() {
  try {
    watchdog.setConfig({
      debug: false,
    });
  } catch (e) {
    console.error('watchdog error')
  }
}


/**
 * watchdog 百分比事件上报
 */
const percentEventCache = {};

function createPercentEvent(eventName) {
  if (percentEventCache[eventName]) {
    return percentEventCache[eventName];
  }
  percentEventCache[eventName] = watchdog.createPercentEvent(eventName);

  return percentEventCache[eventName];
}

// 百分比上报成功
export function watchdogPercentEventSuccess(eventName, fields) {
  try {
    const percentEvent = createPercentEvent(eventName);
    if (fields) {
      percentEvent.addFields({ ...fields });
    }
    percentEvent.success();
  } catch (error) {
    console.log('百分比成功事件报错', error);
  }
}

// 百分比上报失败
export function watchdogPercentEventFail(eventName, fields) {
  try {
    const percentEvent = createPercentEvent(eventName);
    if (fields) {
      percentEvent.addFields({ ...fields });
    }
    percentEvent.fail();
  } catch (error) {
    console.log('百分比失败事件报错', error);
  }
}


export const myConsole = {};

/** watchdog ext字段长度限制 */
const LOG_MAX_LENGTH = 500;
/** 字符串切割成数组 */
function splitString(str, chunkSize) {
  const chunks = [];
  for (let i = 0; i < str.length; i += chunkSize) {
    chunks.push(str.substring(i, i + chunkSize));
  }
  return chunks;
}

/**
 * 线上默认上报，其他环境通过watchdog_send控制
 */
Object.keys(console).forEach((name) => {
  myConsole[name] = (eventName, ...fields) => {
    // watchdog上报长度限制，切割分多个字段上传
    const eventNameJson = JSON.stringify(eventName);
    let logArr;
    if (eventNameJson.length > LOG_MAX_LENGTH) {
      const logStr = JSON.stringify([eventName, ...fields]);
      logArr = splitString(logStr, LOG_MAX_LENGTH);
    } else {
      const logStr = JSON.stringify(fields);
      logArr = [eventNameJson, ...splitString(logStr, LOG_MAX_LENGTH)];
    }
    if (VanEnv !== 'prod') {
      // 非生产环境打印log
      console[name](eventName, ...(fields || []));
    }
    watchdog.sendEvent(...logArr);
  };
});
