//按需加载模块
const login = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/login/login.vue'))
  }, 'login')//这个参数一样的模块，会被打包在一个文件中
}
const actionTest = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/login/action-test'))
  }, 'login')
}

//前端路由
export default [
  {path: '/login', component: login},
  {path: '/action-test', component: actionTest, meta: {title: 'action测试'}}
]
