import crypto from 'crypto-js';
import Base64 from 'crypto-js/enc-base64';
import site from '../../site.js';
import { camelCase } from 'lodash-es';
import { url as oUrl } from '@hll/jssdk';
import { appDownUrl } from '../config';
import { getQueryString, appendObjectToUrl } from '@hll/eapp-utils';
import oUa from '@hll/user-agent';
import { epHlightH5Origins } from '../constant/index.js';
import URI from 'urijs';

// 是否微信小程序环境
const isWxa = (function() {
  const ua = window.navigator.userAgent;
  return /miniProgram/i.test(ua);
})();

// 是否原生环境
const isNative = (function() {
  return !!(
    (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.app) ||
    (window.app && window.app.webcall)
  );
})();

var tools = {};

// action 回调重名会导致window之前绑定的回调被覆盖，进而是action回调丢失
// 在此添加记录对高频出现的 getAppInfo 的action回调函数名进行重写防止回调丢失
let nameCount = 0;

/**
 * 百度经纬度转Gps经纬度
 * @returns {*} {lat, lon}
 */
tools.baiduToGps = function(lat, lon) {
  //百度经纬度转Gps经纬度
  var a = 6378245.0;
  var ee = 0.00669342162296594323;
  var pi = 3.1415926535897932384626;

  function bd09_To_Gcj02(bd_lat, bd_lon) {
    var x = bd_lon - 0.0065;
    var y = bd_lat - 0.006;
    var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * pi);
    var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * pi);
    var gg_lon = z * Math.cos(theta);
    var gg_lat = z * Math.sin(theta);
    var Gcj02 = {
      lon: gg_lon,
      lat: gg_lat,
    };
    return Gcj02;
  }

  function gcj_To_Gps84(gcj_lat, gcj_lon) {
    var gps = transform(gcj_lat, gcj_lon);
    var lontitude = gcj_lon * 2 - gps.lon;
    var latitude = gcj_lat * 2 - gps.lat;
    var Gps84 = {
      lon: lontitude,
      lat: latitude,
    };
    return Gps84;
  }

  function transform(lat, lon) {
    if (outOfChina(lat, lon)) {
      var out_jw = {
        lat: lat,
        lon: lon,
      };
      return out_jw;
    }
    var dLat = transformLat(lon - 105.0, lat - 35.0);
    var dLon = transformLon(lon - 105.0, lat - 35.0);
    var radLat = (lat / 180.0) * pi;
    var magic = Math.sin(radLat);
    magic = 1 - ee * magic * magic;
    var sqrtMagic = Math.sqrt(magic);
    dLat = (dLat * 180.0) / (((a * (1 - ee)) / (magic * sqrtMagic)) * pi);
    dLon = (dLon * 180.0) / ((a / sqrtMagic) * Math.cos(radLat) * pi);
    var mgLat = lat + dLat;
    var mgLon = lon + dLon;
    var transform_jw = {
      lat: mgLat,
      lon: mgLon,
    };
    return transform_jw;
  }

  function outOfChina(lat, lon) {
    if (lon < 72.004 || lon > 137.8347) {
      return true;
    }
    if (lat < 0.8293 || lat > 55.8271) {
      return true;
    }
    return false;
  }

  function transformLat(x, y) {
    var ret = -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * x * y + 0.2 * Math.sqrt(Math.abs(x));
    ret += ((20.0 * Math.sin(6.0 * x * pi) + 20.0 * Math.sin(2.0 * x * pi)) * 2.0) / 3.0;
    ret += ((20.0 * Math.sin(y * pi) + 40.0 * Math.sin((y / 3.0) * pi)) * 2.0) / 3.0;
    ret += ((160.0 * Math.sin((y / 12.0) * pi) + 320 * Math.sin((y * pi) / 30.0)) * 2.0) / 3.0;
    return ret;
  }

  function transformLon(x, y) {
    var ret = 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * x * y + 0.1 * Math.sqrt(Math.abs(x));
    ret += ((20.0 * Math.sin(6.0 * x * pi) + 20.0 * Math.sin(2.0 * x * pi)) * 2.0) / 3.0;
    ret += ((20.0 * Math.sin(x * pi) + 40.0 * Math.sin((x / 3.0) * pi)) * 2.0) / 3.0;
    ret += ((150.0 * Math.sin((x / 12.0) * pi) + 300.0 * Math.sin((x / 30.0) * pi)) * 2.0) / 3.0;
    return ret;
  }

  var gcj_lat_lon = bd09_To_Gcj02(lat, lon);
  return gcj_To_Gps84(gcj_lat_lon.lat, gcj_lat_lon.lon);
};

tools.getCookie = function(name) {
  var arr,
    reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
  if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
  else return null;
};

tools.setCookie = function(name, value, hour, domain) {
  //设置cookie
  var d = new Date();
  d.setTime(d.getTime() + hour * 60 * 60 * 1000);
  var expires = 'expires=' + d.toUTCString();
  var cookieDomain = domain || '.' + document.domain;
  if (/localhost|^172/.test(location.hostname)) {
    cookieDomain = ''; // 本地调试不设置domain
  }
  document.cookie = name + '=' + value + ';path=/;domain=' + cookieDomain + ';' + expires;
};

tools.clearCookie = function() {
  var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
  if (keys) {
    for (var i = keys.length; i--; ) {
      document.cookie =
        keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString();
    }
  }
};

// 清除指定cookie
const delCookie = (name, domain) => {
  document.cookie = `${name}=;path=/;domain=${domain};expires=${new Date(0).toUTCString()}`
};

//支付状态pay_status
const PayStatus = {
  //非在线支付，未支付
  NO_PAY: 0,
  //已支付
  PAY_SUCCESS: 1,
  //支付失败
  PAY_FINISHED: 2,
  //退款中
  REFUND_WAIT: 3,
  //退款成
  REFUND_SUCCESS: 4,
  //支付中
  PAY_WAIT: 5,
};

//订单状态OrderStatus
const OrderStatus = {
  //配对中
  ORDER_ASSIGNING: 0,
  //进行中(待装货)
  ORDER_INCOMPLETE: 1,
  //已完成
  ORDER_COMPLETED: 2,
  //已取消
  ORDER_CANCELLED: 3,
  //已拒绝(表示B单拒绝)
  ORDER_REJECTED: 4,
  //超时
  ORDER_TIMEOUT: 5,
  //未支付或者支付中
  ORDER_PAYING: 6,
  //已装货(运送中)
  ORDER_LOADED: 7,
  //5天未装货自动取消
  ORDER_CANCELLED_FIVE_DAYS: 8,
  //10天未完成自动取消
  ORDER_CANCELLED_TEN_DAYS: 9,
  //司机完成
  DRIVER_COMPLETED: 10,
  //异常完成
  ABNORMAL_COMPLETED: 11,
  //结清现金
  SETTLEMENT_CASH: 12,
};

//计价类型OrderPriceType
const OrderPriceType = {
  // 起步价
  START_PRICE: 1,
  //超里程价
  EXCEED_PRICE: 2,
  //拉拉券
  COUPON: 3,
  //额外需求产生的费用
  SPEC_REQ_TOTAL: 4,
  //小费
  TIPS: 5,
  //规格
  VEHICLE_STD_TOTAL: 6,
  //额外补贴
  PERQUISITE_PRICE: 7,
  //额外费用
  PERQUISITE_FEE: 8,
};

/**
 * 根据订单API数据，整理订单价格详情
 * @param data _m=order&_a=a_order_detail返回的数据
 * @returns {Array}
 */
tools.priceFormat = function(data) {
  let onlinePay = []; //在线已支付
  let noPay = []; //未支付
  let waitPay = []; //待支付
  let cashPay = []; //现金支付

  let priceItem = data.price_item;

  for (let item of priceItem) {
    if (-1 !== [1, 3, 4].indexOf(item.pay_status) || (item.pay_status === 0 && item.type === 3)) {
      addItem(onlinePay, item);
    } else {
      let orderStatus = data.order_status;
      if (-1 !== [3, 4, 5, 8, 9].indexOf(orderStatus)) {
        addItem(noPay, item);
      } else if (-1 !== [2, 12].indexOf(orderStatus)) {
        addItem(cashPay, item);
      } else {
        addItem(waitPay, item);
      }
    }
  }

  //添加item进入对应的数组
  function addItem(arr, item) {
    if (item.type === 1) {
      arr.push({
        name: `起步价(${data.vehicle_type_name})`,
        value: item.value_fen,
      });
    } else if (item.type === 2) {
      arr.push({
        name: `超过里程(${parseInt(data.exceed_distance / 1000) + 1}公里)`,
        value: item.value_fen,
      });
    } else if (item.type === 3) {
      arr.push({
        name: `优惠券抵扣`,
        value: 0 - item.value_fen,
      });
    } else if (item.type === 4) {
      //额外需求
      for (let i = 0; i < data.spec_req_price_item.length; i++) {
        arr.push({
          name: data.spec_req_price_item[i].name,
          value: data.spec_req_price_item[i].value_fen,
        });
      }
    } else if (item.type === 5) {
      arr.push({
        name: `小费`,
        value: item.value_fen,
      });
    } else if (item.type === 6) {
      //规格费用
      for (let i = 0; i < data.vehicle_std_price_item.length; i++) {
        arr.push({
          name: data.vehicle_std_price_item[i].name,
          value: data.vehicle_std_price_item[i].value_fen,
        });
      }
    } else if (item.type === 8) {
      //额外费用
      let content = data.rear_pay_remark
        .replace('1', '搬运费')
        .replace('2', '高速路桥费')
        .replace('3', '停车费')
        .replace('4', '逾时等候费');
      console.log('content', content);
      if (content !== '') {
        arr.push({
          name: `额外费用(${content})`,
          value: item.value_fen,
        });
      } else {
        arr.push({
          name: `额外费用`,
          value: item.value_fen,
        });
      }
    } else if (item.type === 9) {
      //额外需求
      for (let i = 0; i < data.surcharge_price_item.length; i++) {
        arr.push({
          name: data.surcharge_price_item[i].name,
          value: data.surcharge_price_item[i].value_fen,
        });
      }
    } else if (item.type === 10) {
      arr.push({
        name: `热点区域最低消费差额`,
        value: item.value_fen,
      });
    } else if (item.type === 11) {
      arr.push({
        name: `附加费封顶折扣`,
        value: item.value_fen,
      });
    }
  }

  //过滤0元的
  onlinePay = onlinePay.filter(item => {
    return item.value;
  });
  noPay = noPay.filter(item => {
    return item.value;
  });
  waitPay = waitPay.filter(item => {
    return item.value;
  });
  cashPay = cashPay.filter(item => {
    return item.value;
  });

  let list = [];

  if (onlinePay.length) {
    list.push({
      title: '在线已支付',
      items: onlinePay,
    });
  }
  if (waitPay.length) {
    list.push({
      title: '待支付',
      items: waitPay,
    });
  }
  if (noPay.length) {
    list.push({
      title: '未支付',
      items: noPay,
    });
  }
  if (cashPay.length) {
    list.push({
      title: '现金支付',
      items: cashPay,
    });
  }

  return list;
};

/**
 * 获取url中的参数
 */
tools.getQueryString = function(name, byHash = false) {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  let str = window.location.search.substr(1);
  if (byHash) {
    let hash = window.location.hash;
    let index = hash.indexOf('?');
    str = index === -1 ? '' : hash.substr(index + 1);
  }
  let r = str.match(reg);
  if (r != null) return unescape(r[2]);
  return null;
};

/**
 * 实现活动页面入口统计
 * @pointName 埋点名称
 * @type event 事件，page 页面载入，默认为事件（根据自己需要可传入任意字符串）
 * @channel 入口渠道。若不传，type为event时不做处理，type为page时获取userAgent的值
 */
tools.bdDataCount = function(pointName, type = 'event', channel) {
  if (!channel && type === 'page') {
    if (this.getCookie('revision')) {
      channel = 'APP';
    } else {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        // 判断是从哪打开的，合并为两处 'timeline' 及 'message'
        let from = this.getQueryString('from') || '';
        if (from === 'groupmessage' || from === 'singlemessage') {
          from = 'message';
        }
        channel = 'WX_' + from;
      } else if (ua.match(/QQ/i) == 'qq') {
        channel = 'QQ';
      } else {
        channel = 'Others';
      }
    }
  }
  if (window._hmt) {
    window._hmt.push(['_trackEvent', pointName, type, channel]);
  }
};

/**
 * 下载企业app  支持在用户端中下载
 * @sources 渠道值
 */
tools.downEApp = function(sources) {
  let time = new Date().getTime();
  let url = appDownUrl + (sources ? '&ref=' + sources : '');
  let urlJump =
    location.origin +
    '/?time_stamp=' +
    time +
    '#/intro?eappdown=1' +
    (sources ? '&sources=' + sources : '');
  if (app && app.webcall) {
    app.webcall(
      JSON.stringify({
        action: 'eappStoreUrl',
        url: url,
      }),
    );
  } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.app) {
    window.webkit.messageHandlers.app.postMessage(
      JSON.stringify({
        action: 'eappStoreUrl',
        url: urlJump,
      }),
    );
  } else {
    location.href = url;
  }
};

/**
 * 获取环境信息  0 外部/1 用户端/2 企业端
 */
tools.envInit = function() {
  let appEnvTemp = {
    env: 0,
    revision: '', // 企业App版本号，只有env为2时才有
    version: '', // 企业App版本号，只有env为2时才有
  };
  let setAppEnv = ua => {
    let res = ua.match(/huolala\((.*?)\)/);
    if (res) {
      if (res.length === 2) {
        let params = res[1].split(',');
        appEnvTemp.revision = params[0];
        appEnvTemp.version = params[3];
      }
      appEnvTemp.env = 2; // 企业端
    } else {
      appEnvTemp.env = 1; // 用户端
    }
  };
  return new Promise(resolve => {
    if (
      (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.app) ||
      (app && app.webcall)
    ) {
      let ua = navigator.userAgent;
      // 兼容ios 9.x 及以下
      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.app) {
        const callbackName = `shareResult${nameCount++}`;
        window[callbackName] = function(textUserAgent) {
          setAppEnv(textUserAgent);
          resolve(appEnvTemp);
        };
        window.webkit.messageHandlers.app.postMessage(
          JSON.stringify({
            action: 'getAppInfo',
            callback: callbackName,
          }),
        );
      } else {
        setAppEnv(ua);
        resolve(appEnvTemp);
      }
    } else {
      console.log({appEnvTemp});

      resolve(appEnvTemp);
    }
  });
};
/**
 * 获取useragent /ios/android
 */
tools.getUaEvn = function() {
  const ua = window.navigator.userAgent;
  let app = '';
  if (/(iPhone|iPad|iPod|IOS)/i.test(ua)) {
    app = 'ios';
  } else if (/android/i.test(ua)) {
    app = 'android';
  }
  return app;
};

/**
 *  区分微信环境： wxa 微信小程序/wechat 微信浏览器
 */
tools.getWxEvn = function() {
  return new Promise((reslove, reject) => {
    const ua = window.navigator.userAgent;
    let app = '';
    if (!/MicroMessenger/i.test(ua)) {
      reslove(app);
      return;
    }
    if (/miniProgram/i.test(ua)) {
      // 微信小程序
      app = 'wxa';
      reslove(app);
    } else if (window?.wx?.miniProgram) {
      window?.wx?.miniProgram.getEnv(function(res) {
        app = res.miniProgram ? 'wxa' : 'wechat';
        reslove(app);
      });
    } else {
      // 微信浏览器
      app = 'wechat';
      reslove(app);
    }
  });
};

// 金额元转分
tools.moneyToFen = money => {
  if (!money) {
    return money;
  }
  return Math.round(money * 100);
};
// 金额分转元(默认保留两位小数)
tools.moneyToYuan = (money, float = 2) => {
  if (!money) {
    return money;
  }
  return Number((money / 100).toFixed(float));
};
// 格式化金额
tools.formatMoney = (money, float = 0) => {
  if (!money) {
    return money;
  }
  return Number(money.toFixed(float));
};
// Blob转File
tools.blobToFile = (blob, fileName) => {
  blob.lastModifiedDate = new Date();
  blob.name = fileName;
  return blob;
};
// 将base64转为文件
tools.base64ToFile = (dataUrl, fileName) => {
  let arr = dataUrl.split(',');
  let mime = arr[0].match(/:(.*?);/)[1];
  // 为了文件名不重复统一重命名
  fileName = `${new Date().getTime()}_${Math.floor(Math.random() * 1000000)}.${mime.substr(
    mime.indexOf('/') + 1,
  )}`;
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  let file = null;
  try {
    file = new File([u8arr], fileName, { type: mime });
  } catch (err) {
    if (err.name === 'TypeError') {
      // 解决低版本浏览器没有new File()问题
      let blob = null;
      try {
        blob = new Blob([u8arr], { type: mime });
      } catch (e) {
        // 解决低版本浏览器没有new Blob()问题
        let BlobBuilder = (window.BlobBuilder =
          window.BlobBuilder ||
          window.WebKitBlobBuilder ||
          window.MozBlobBuilder ||
          window.MSBlobBuilder);

        if (e.name === 'TypeError' && BlobBuilder) {
          let builder = new BlobBuilder();
          builder.append(u8arr);
          blob = builder.getBlob(mime);
        }
      }
      file = tools.blobToFile(blob, fileName);
    }
  }
  return file;
};
// 文件转base64
tools.fileToBase64 = (file, callback) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    callback(reader.result);
  };
  reader.onerror = error => {
    callback('');
  };
};

/**
 * 时间格式化
 * @param timeStamp 时间戳 或 时间格式
 * @param fmt 日期格式
 * @return str 返回fmt格式
 */
tools.formatDate = (timeStamp, fmt) => {
  if (!timeStamp) {
    return '';
  }
  timeStamp = timeStamp.toString();
  if (Number(timeStamp) && timeStamp.length === 10) {
    timeStamp = (timeStamp * 1000).toString(); // 秒转为毫秒
  }
  timeStamp = timeStamp.replace(/-/g, '/');

  let date = new Date(timeStamp);
  let o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length),
      );
    }
  }
  return fmt;
};
// 获取app信息
tools.getAppInfo = (ua = navigator.userAgent) => {
  return new Promise(resolve => {
    const { is_hll_user_app } = oUa.parse();
    if (/miniProgram/i.test(ua)) {
      // 微信小程序
      let token = setWXInfo();
      resolve(token);
    } else if (is_hll_user_app) {
      const token = getQueryString("token");
      resolve(token);
    } else if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.app
    ) {
      // 兼容ios 9.x 及以下
      const callbackName = `shareResult${nameCount++}`;
      window[callbackName] = function(textUserAgent) {
        console.log('textUserAgent', textUserAgent);
        let token = setAppInfoByUserAgent(textUserAgent); // 修改手机参数
        resolve(token);
      };
      window.webkit.messageHandlers.app.postMessage(
        JSON.stringify({
          action: 'getAppInfo',
          callback: callbackName,
        }),
      );
    } else {
      let token = setAppInfoByUserAgent(ua);
      resolve(token);
    }
  });

  // 存储微信小程序信息
  function setWXInfo() {
    // let urlToken = tools.getQueryString('token', true);
    const token = oUrl.getParam('token') || '';
    if (token) {
      tools.setCookie('token', token, 30);
    }
    console.log('微信小程序token', token);
    return token;
  }

  // 存储app信息
  function setAppInfoByUserAgent(ua) {
    try {
      console.log('ua', ua);
      if (typeof ua !== 'string') {
        return ''; // 其他app getAppInfo可能返回json对象，先排除。企业app此处为字符串
      }
      // let urlToken = tools.getQueryString('token', true); // token优先从url中获取
      let urlToken = oUrl.getParam('token');
      let arr = [];
      let res = ua.match(/huolala\((.*?)\)/);
      // console.log('urltoken', urlToken);
      // console.log(res);
      if (res && res.length && res.length === 2) {
        // arr = [revision, os, channel, version, device_type, device_id, token]
        arr = res[1].split(',');
        // 公共webview长度为6 企业在此基础上加了token 长度变为7
        if (arr.length >= 6) {
          let token = urlToken || arr[6] || '';
          // 把revision, os, channel, version, device_type, device_id, token信息设置到cookie中
          tools.setCookie('revision', arr[0], 30);
          tools.setCookie('os', arr[1], 30);
          tools.setCookie('channel', arr[2], 30);
          tools.setCookie('version', arr[3], 30);
          tools.setCookie('device_type', arr[4], 30);
          tools.setCookie('device_id', arr[5], 30);
          tools.setCookie('token', token, 30);
          return token;
        } else {
          return '';
        }
      } else {
        // ua 正常都是字符串格式
        // // 兼容新的数据格式
        // res = JSON.parse(ua);
        // console.log('res', res);
        // if (res.revision) {
        //   let token = urlToken || res.token || '';
        //   tools.setCookie('revision', res.revision, 30);
        //   tools.setCookie('os', res.os, 30);
        //   tools.setCookie('channel', res.channel, 30);
        //   tools.setCookie('version', res.version, 30);
        //   tools.setCookie('device_type', res.device_type, 30);
        //   tools.setCookie('device_id', res.device_id, 30);
        //   tools.setCookie('token', token, 30);
        //   return token;
        // } else {
        //   return '';
        // }
        let token = '';
        if (urlToken) {
          token = urlToken;
          tools.setCookie('token', urlToken, 30);
        }
        return token;
      }
    } catch (e) {
      console.error(e);
      return '';
    }
  }
};

/**
 * getCommonParams 获取验签参数
 * @param {*} parmas 请求参数
 * @param {*} method 请求方法
 * 生成规则 https://wiki.huolala.work/pages/viewpage.action?pageId=28572670
 */
tools.getCommonParams = function(parmas = {}, method = 'GET') {
  let secretId = 'qynSVgkAD5XmF0wTkzuwgtt8RzTlHfxxhOS2'; //签名id，服务端返回
  let secretKey = 'XZR2R000CFybcninYDWnI3kJLmdk7IsP';
  let timestamp = new Date().getTime(); // 时间戳
  let randomStr = getRandom(8); // 生成随机数
  let paramObj = {
    secret_id: secretId,
    timestamp: timestamp,
    nonce: randomStr,
    ...parmas,
  };
  let data = jsonSort(paramObj); // 将参数排序
  let spliceStr = spliceString(data); // 拼接字符串
  spliceStr = `${method}${getHostname()}/index.php?${spliceStr}`; // 添加请求方法，域名
  console.log('spliceStr', spliceStr);
  spliceStr = Base64.stringify(crypto.HmacSHA256(spliceStr, secretKey)); // 加密
  console.log('spliceStr', spliceStr);
  console.log('parmas', parmas);
  return {
    secret_id: secretId,
    timestamp: timestamp,
    nonce: randomStr,
    signature: spliceStr,
  };
};

/**
 * 获取当前域名
 * debugger模式与webpack代理域名一致
 */
export function getHostname() {
  if (/-dev|localhost|^192/.test(location.href)) {
    // 开发环境
    return site.hostname.split('//')[1];
  } else {
    let host = location.hostname;
    let eapiStgUrl = 'eapi-stg.huolala.cn';
    let eapiPreUrl = 'eapi-pre.huolala.cn';
    let eapiGraUrl = 'eapi-gra.huolala.cn';
    let eapiProUrl = 'eapi.huolala.cn';
    if (/-stg/.test(host)) {
      return eapiStgUrl;
    } else if (/-pre/.test(host)) {
      return eapiPreUrl;
    } else if (/-gra/.test(host)) {
      return eapiGraUrl;
    } else {
      return eapiProUrl;
    }
  }
}

/**
 * 将对象拼接成用传入字符连接的字符串
 * @param {*} jsonData
 */
function spliceString(jsonData, char = '&') {
  if (jsonData != null && jsonData instanceof Object) {
    let keys = Object.keys(jsonData);
    let str = '';
    keys.forEach((key, i) => {
      str += `${key}=${jsonData[key]}`;
      // 如果不是最后一个，拼接&
      if (i != keys.length - 1) {
        str += '&';
      }
    });
    return str;
  } else {
    console.error('jsonData == null');
    return '';
  }
}

/**
 * 获取任意长度的字母数字随机数
 * @param {*} len 需要生成的字符串长度， 默认：8
 */
function getRandom(len = 8) {
  var chars = [
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 
    'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
    'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 
    'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
  ];
  var str = '';
  for (let i = 0; i < len; i++) {
    var id = Math.ceil(Math.random() * chars.length - 1);
    str += chars[id];
  }
  return str;
}

/**
 * 字典序升序排序
 * @param {*} jsonData 字典对象
 */
function jsonSort(jsonData) {
  try {
    let tempJsonObj = {};
    let sdic = Object.keys(jsonData).sort();
    sdic.map((item, index) => {
      tempJsonObj[item] = jsonData[sdic[index]];
    });
    return tempJsonObj;
  } catch (e) {
    return jsonData;
  }
}

tools.getInterfaceName = function(url, name = '_m') {
  if (url === null || url === '') {
    console.error("url === null || null === '' ");
    return '';
  }
  let reg = new RegExp('(^|\\?|&)' + name + '=([^&#]*)(&|#|$)');
  let r = url.match(reg);
  if (r != null) return decodeURIComponent(r[2]);
};

/**
 * 将传入的对象value为number的转为string
 * 大小b合并接口返回大量数字格式与原有全string格式不合，全量转string
 * 直接修改原始对象 没有返回值
 */
tools.numberToString = function(data) {
  if (typeof data === 'object' && data !== null) {
    for (let key in data) {
      if (typeof data[key] === 'number') {
        data[key] = data[key].toString();
      }
      if (typeof data[key] === 'object') {
        tools.numberToString(data[key]);
      }
    }
  }
};

// 吐出一个不存在于 compareStr 中的标识字符串
tools.pickTagStr = compareStr => {
  let tag = '@rr_';
  let index = 0;
  while (compareStr.indexOf(tag) !== -1) {
    tag += index++;
  }
  return tag;
};
// 能否被JSON.parse，可能是字符串 不一定是JSON
tools.canJsonParse = str => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};
// 处理文本中超长的数字变量，通过正则将其转为字符串
tools.changeLongToString = str => {
  const tag = tools.pickTagStr(str);
  return str
    .replace(/:\d{17,}/g, item => `:"${tag}_r_${item.substr(1)}"`)
    .replace(new RegExp(`${tag}_r_\\s`, 'g'), '')
    .replace(new RegExp(`${tag}_r_`, 'g'), '');
};
// 解析接口响应返回的text类型的response
tools.parseResponse = str => {
  if (typeof str !== 'string') {
    return str;
  }
  try {
    // 先用最简单的方式处理(异常情况：用户输入字段(如：备注等)匹配到替换规则，导致JSON错位，需特殊处理)
    const response = JSON.parse(tools.changeLongToString(str));
    return response;
  } catch (e) {
    const reg = /:\d{17,}/;
    const tag = tools.pickTagStr(str);
    // 直接对整个str遍历，逐个处理超长的数据类型
    while (reg.test(str)) {
      const strBack = str;
      str = str.replace(reg, item => `:"${tag}_r_${item.substr(1)}"`); // 需转换 _r_ 占位并添加""
      // 变化后解析失败，判定为无需转换的内容。回退并添加无需转换标识
      if (!tools.canJsonParse(str)) {
        str = strBack.replace(reg, item => `:${tag}_n_${item.substr(1)}`); // 不需要转换 _n_ 占位
      }
    }
    const result = str
      .replace(new RegExp(`${tag}_r_\\s`, 'g'), '')
      .replace(new RegExp(`${tag}_r_`, 'g'), '')
      .replace(new RegExp(`${tag}_n_\\s`, 'g'), '')
      .replace(new RegExp(`${tag}_n_`, 'g'), '');

    // 仍有错就抛出去吧，方便排查
    return JSON.parse(result);
  }
};

// 将对象的key转为驼峰
tools.transfromCamelCase = (obj = {}) => {
  const newObj = {};
  for (const [key, value] of Object.entries(obj)) {
    newObj[camelCase(key)] = value;
  }
  return newObj;
};



/**
 * 获取环境信息 {0:外部 1:用户端 2:企业端 3:小程序 4:wechat}
 */
function getEnv() {
  return new Promise((resolve, reject)=>{
    tools.envInit().then((res) => {
      let reVal = res.env;
      if(reVal === 0) {
        tools.getWxEvn().then(wxres => {
          if(wxres == 'wxa') {
            reVal = 3;
          } else if (wxres == 'wechat') {
            reVal = 4;
          }
          resolve(reVal);
        });
      } else {
        resolve(reVal);
      }
    })
  })
}

function getEpFrom() {
  return localStorage.getItem('epFrom') || getQueryString('ep_from');
}

function customLocationHref(url) {
  let newUrl = url;
  const epFrom = getEpFrom();
  if (['ep_h5','ep_harmony'].includes(epFrom)) {
    const ep_from = getQueryString('ep_from')
    newUrl = appendObjectToUrl(url, {
      ep_from,
    });
    if (top.location !== self.location) { 
      top.location = newUrl;
    }
  }
  window.location.href = newUrl;
}


/**
 * 处理来自main frame的消息
 * 来自ep -light H5 域名的消息
*/
const handleMainFrameEventListener = (fn) => {
  // 使用方法
  const listener = new MessageListener();

  // 添加监听器，每次触发后自动移除
  listener.addListener((event) => {
    const { data, origin } = event;
    console.log('收到消息:', data, origin);
    const isTrustOrigin = epHlightH5Origins.includes(origin);
    if (!isTrustOrigin) {
      return;
    }
    (window)?.[fn]?.(data);
  });

}
// fn扩展
function callNativeOrH5(params, fn) {
  tools
    .getWxEvn()
    .then(res => {
      const isWxa = res == 'wxa' ? true : false;
      const isAppH5 = !!(getEpFrom() && window.parent); // 小程序编译投放H5 - iframe场景
      const h5Params = fn?.appH5 || params;
      const isAndroid = !!(window?.app && window?.app?.webcall);
      const isIOS = !!window.webkit?.messageHandlers?.app;
      const isShareFail = fn && typeof fn === 'object' && fn?.h5; // 使用场景： order-share 分享后登录失效，小程序公众号webview内调取eappweb 登录弹窗；
      switch (true) {
        case isAppH5:
          window.parent.postMessage(h5Params, '*');
          if (params?.callback) {
            handleMainFrameEventListener(params.callback);
          }
          break;
        case isAndroid:
          window.app.webcall(JSON.stringify(params));
          break;
        case isIOS:
          window.webkit.messageHandlers.app.postMessage(params);
          break;
        case isWxa:
          if (fn && typeof fn === 'object' && fn?.wxa) {
            fn.wxa();
          } else {
            window.wx.miniProgram.postMessage(params);
          }
          break;
        case isShareFail:
          fn.h5();
          break;
        default:
          console.log('callAction', 'error::callAction 未匹配任一场景');
          break;
      }
    })
    .catch(err => {
      console.log('>>> ~ tools.getWxEvn ~ err', err);
    });
}

/**
 * 在浏览器中打开当前页面
 * @url 打开页面的url
 */
tools.openUrlInBrowser = function(url) {
  if (window.app && window.app.webcall) {
    window.app.webcall(
      JSON.stringify({
        action: 'eappStoreUrl',
        url,
      }),
    );
  } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.app) {
    window.webkit.messageHandlers.app.postMessage(
      JSON.stringify({
        action: 'eappStoreUrl',
        url,
      }),
    );
  } else {
    location.href = url;
  }
}

// ios调用
function callNativeIos({ action, callback }) {
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.app
  ) {
    // IOS调用
    window.webkit.messageHandlers.app.postMessage(JSON.stringify({
      action,
      callback,
    }));
  }
}

function callNativeAndroid({ action, callback }) {
  if (app && app.webcall) {
    // 安卓调用
    app.webcall(JSON.stringify({
      action,
      callback,
    }));
  }
}

function imageUrl2Base64(url, callback) {
	if(url) {
		let image = new Image();
		//解决跨域报错，必须写在赋值给image.src的前面，否则偶尔报错，也可以这样写image.setAttribute('crossOrigin', 'anonymous');
		image.crossOrigin = 'anonymous';
		image.src = url;
		//等待图片加载完成，转换图片为base64，异步
		image.onload = function() {
			let canvas = document.createElement('canvas');
			canvas.width = image.width;
			canvas.height = image.height;
			let ctx = canvas.getContext('2d');
			ctx.drawImage(image, 0, 0, image.width, image.height);
			let base64 = canvas.toDataURL('image/png');
			callback(base64)
		}
    image.onerror = function(e) {
      console.log(e)
      console.log(url)
      console.log('图片加载失败')
    }
	}
}


/**
 * 判断某个原生DOM元素是否不在屏幕可见区内
 * @param {*} el 原生DOM元素
 */
tools.isElementInViewport = function(el) {
  let rect = el.getBoundingClientRect();
  return !(
    rect.top >= (window.innerHeight || document.documentElement.clientHeight) ||
    rect.bottom <= 0
  );
};

// 是否在ios环境
const isIos = (function() {
  return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
})();

function addQueryParams(baseUri, queryParams) {
  // 使用URI.js解析基础URI
  const uri = new URI(baseUri);

  // 循环添加查询参数
  Object.keys(queryParams).forEach(key => {
    uri.addQuery(key, queryParams[key]);
  });

  // 返回最终的URI字符串
  return uri.toString();
}

export {
  isWxa,
  isNative,
  isIos,
  tools,
  getEpFrom,
  callNativeOrH5,
  callNativeIos,
  callNativeAndroid,
  getEnv,
  imageUrl2Base64,
  delCookie,
  customLocationHref,
  addQueryParams,
};
