// 承运人协议
const carrierAgreement = (resolve) => {
  require.ensure(
    [],
    () => {
      resolve(
        require('../../components/carrier-agreement/carrier-agreement.vue')
      )
    },
    'carrierAgreement'
  )
}

// 前端路由
export default [
  {
    path: '/carrier-agreement/:agreementId',
    name: 'carrierAgreement',
    component: carrierAgreement,
    meta: { title: '承运人协议' }
  }
]
