// 客服中心 - 按需加载模块
// 反馈列表
const feedbackList = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/customer-service/feedback-list.vue'))
  }, 'customer-service')
};
// 客服中心首页
const callCenterIndex = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/customer-service/call-center-index.vue'))
  }, 'customer-service')
};
// 问题详情
const issueDetail = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/customer-service/issue-detail.vue'))
  }, 'customer-service')
};
// 问题列表
const issueList = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/customer-service/issue-list.vue'))
  }, 'customer-service')
};
// 订单问题
const orderIssue = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/customer-service/order-issue.vue'))
  }, 'customer-service')
};
// 订单列表
const orderList = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/customer-service/order-list.vue'))
  }, 'customer-service')
};
// 常见问题详情
const commonIssueDetail = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/customer-service/common-issue-detail.vue'))
  }, 'customer-service')
};

// 在线客服
const onlineCustomer = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/customer-service/online-customer'))
  }, 'customer-service')
};


  // 前端路由
  export default [
    {
      path: '/feedback-list',
      component: feedbackList,
      meta: {title: '我的反馈记录'}
    },
    {
      path: '/call-center-index',
      component: callCenterIndex,
      meta: {title: '客服中心'}
    },
    {
      path: '/issue-detail',
      component: issueDetail,
      meta: {title: '投诉问题'}
    },
    {
      path: '/issue-list',
      component: issueList
    },
    {
      path: '/order-issue',
      component: orderIssue,
      meta: {title: '请选择投诉的问题'}
    },
    {
      path: '/order-list',
      component: orderList,
      meta: {title: '选择投诉的订单'}
    },{
      path: '/common-issue-detail',
      component: commonIssueDetail,
      meta: {title: '反馈问题'}
    },{
      path: '/online-customer',
      name: 'onlineCustomer',
      component: onlineCustomer,
      meta: {title: '货拉拉企业版'}
    }
  ]