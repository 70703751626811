// 报表

// 企业支出报表
const epExpenseReport = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/report/ep-expense.vue'))
  }, 'report')
};
// 企业订单报表
const epOrderReport = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/report/ep-order.vue'))
  }, 'report')
};

// 前端路由
export default [
  {
    path: '/report/ep-expense',
    component: epExpenseReport,
    meta: {
      title: ' '
    }
  }, {
    path: '/report/ep-order',
    component: epOrderReport,
    meta: {
      title: ' '
    }
  }
]
