/**
 * 定义vue混入
 */
import sa from 'sa-sdk-javascript';

export default {
  install(Vue) {
    // 混入
    Vue.mixin({
      data() {
        return {
          sa: sa
        }
      },
      computed: {
        relations() {
          return this.$store.state.keepAlive.relations
        },
        keepAliveComponents() {
          return this.$store.state.keepAlive.components
        }
      },
      mounted() {
        this.iosInputBack()
      },
      // 处理keep-alive页面缓存相关逻辑
      beforeRouteEnter(to, from, next) {
        next(vm => {
          let indexTemp = vm.keepAliveComponents.indexOf(to.name)
          if(vm.relations[to.name]) {
            // 进的是第一个需要缓存的页面 无论从哪进 存
            if(indexTemp === -1) {
              // 这是第一个需要被缓存的页面 把之前的删掉 直接用新数组
              vm.$store.commit('setAliveComponents', [to.name])
            }
          } else {
            // 进的不是第一个需要缓存的页面
            let aliveTemp = [...vm.keepAliveComponents]
            if(aliveTemp.length >= 1) {
              // 长度不为0 第一个肯定是头 只需判断要去的页面是不是子页面 是则存 不是则全清
              let from = aliveTemp[0]
              let aliveNeedArr
              if(vm.relations[from]) {
                aliveNeedArr = [from, ...vm.relations[from]]
              } else {
                aliveNeedArr = [from]
              }
              if(aliveNeedArr.indexOf(to.name) !== -1) {
                if(indexTemp === -1) {
                  aliveTemp.push(to.name)
                  vm.$store.commit('setAliveComponents', aliveTemp)
                }
              } else {
                vm.$store.commit('setAliveComponents', [])
              }
            } else {
              // 长度为0 没有被缓存的页面 表明不是从头来的 全清
              vm.$store.commit('setAliveComponents', [])
            }
          }
        })
      },
      methods: {
        // 解决ios微信里输入框键盘弹起无法回退的问题
        iosInputBack() {
          let ua = navigator.userAgent
          if (/MicroMessenger/i.test(ua) && /(iPhone|iPad|iPod|iOS)/i.test(ua)) {
            let inputArr = document.querySelectorAll('input');
            inputArr.forEach(item => {
              if(!item.addMixinBlur) {
                item.addMixinBlur = true
                item.addEventListener('blur', () => {
                  let scrollTop = document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop;
                  document.documentElement.scrollTop = document.body.scrollTop = scrollTop;
                })
              }
            })
          }
        },
      }
    })
  }
}
