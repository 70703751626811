const epCreate = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/ep-create/index.vue'));
    },
    'epCreate'
  );
};

export default [
  {
    path: '/ep-create',
    name: 'epCreate',
    component: epCreate,
    meta: { title: '创建企业' }
  }
];
