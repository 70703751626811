/*
 * @Author: sybil.chen
 * @Date: 2022-10-13 14:48:35
 * @LastEditTime: 2022-10-13 16:59:55
 * @LastEditors: sybil.chen
 * @Description: coupe公共配置

 */
const state = {
  coupeData: {
    couponToTaskCenterLink: '',
  },
  coupeDataHasLoad: false,
};
const getters = {
  getCouponData: (state) => state.coupeData || {},
  coupeDataHasLoad: (state) => state.coupeDataHasLoad,
};

const mutations = {
  updateCouponData(state, data = {}) {
    state.coupeData = data || {};
    state.coupeDataHasLoad = true;
  },
};
export default { state, getters, mutations };
