// 按需加载模块
const inboxDetail = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/inbox-detail/inbox-detail'))
  }, 'inbox-detail')
};

// 前端路由
export default [
  {
    path: '/inbox-detail',
    component: inboxDetail,
    meta: {title: '收件箱详情'}
  }
]