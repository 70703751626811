//按需加载模块
const discountCoupon = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/discount-coupon/discount-coupon.vue'))
  }, 'discount-coupon')//这个参数一样的模块，会被打包在一个文件中
}
const discountCouponList = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/discount-coupon/discount-coupon-list.vue'))
  }, 'discount-coupon')//这个参数一样的模块，会被打包在一个文件中
}
  
//前端路由
export default [
  {path: '/discount-coupon', component: discountCoupon, meta: {title: '使用优惠券'}},
  {path: '/discount-coupon-list', component: discountCouponList, meta: {title: '我的优惠券'}}
]
  