/*
 * @description: 企业员工退出企业
 */
const staffExitApply = reslove => {
  require.ensure(
    [],
    () => {
      reslove(require('@/components/staff-exit/apply.vue'));
    },
    'staff-exit'
  );
};

export default [
  {
    path: '/staff-exit/apply',
    component: staffExitApply,
    meta: {
      title: '退出企业申请'
    }
  }
];
