// vue-router 外层公用部分
const main = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/main.vue'))
  }, 'guide')
};
// 首页
const index = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/index.vue'))
  }, 'guide')
};
// 下单问题
const orderNow = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/order-now.vue'))
  }, 'guide')
};
const orderFuture = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/order-future.vue'))
  }, 'guide')
};
const orderCancel = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/order-cancel.vue'))
  }, 'guide')
};
// 费用问题
const feeMode = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/fee-mode.vue'))
  }, 'guide')
};
const feeCarry = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/fee-carry.vue'))
  }, 'guide')
};
const feeCarrySub = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/common/fee-carry-sub.vue'))
  }, 'guide')
};
const feePay = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/fee-pay.vue'))
  }, 'guide')
};
const feeComplain = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/fee-complain.vue'))
  }, 'guide')
};
const feeCalculator = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/fee-calculator.vue'))
  }, 'guide')
};
const feeStandard = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/fee-standard.vue'))
  }, 'guide')
};
const feeCharge = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/fee-charge.vue'))
  }, 'guide')
};
const feeChargeSub = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/common/fee-charge-sub.vue'))
  }, 'guide')
};
// 发票问题
const invoiceElectron = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/invoice-electron.vue'))
  }, 'guide')
};
const invoiceElectronSub = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/common/invoice-electron-sub.vue'))
  }, 'guide')
};
const invoicePaper = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/invoice-paper.vue'))
  }, 'guide')
};
const invoicePaperSub = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/common/invoice-paper-sub.vue'))
  }, 'guide')
};
const invoiceApply = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/invoice-apply.vue'))
  }, 'guide')
};
const invoiceAptitude = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/invoice-aptitude.vue'))
  }, 'guide')
};
const invoiceAptitudeSub = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/common/invoice-aptitude-sub.vue'))
  }, 'guide')
};
const invoiceInfo = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/invoice-info.vue'))
  }, 'guide')
};
const invoiceInfoSub = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/common/invoice-info-sub.vue'))
  }, 'guide')
};
// 货保问题
const policyService = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/policy-service.vue'))
  }, 'guide')
};
const policyOnline = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/policy-online.vue'))
  }, 'guide')
};
// 管理问题
const manageInfo = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/manage-info.vue'))
  }, 'guide')
};
const manageInfoSub = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/common/manage-info-sub.vue'))
  }, 'guide')
};
const manageIntro = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/manage-intro.vue'))
  }, 'guide')
};
const manageChildDepart = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/manage-child-depart.vue'))
  }, 'guide')
};
const manageDepartManager = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/manage-depart-manager.vue'))
  }, 'guide')
};
const manageReport = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/manage-report.vue'))
  }, 'guide')
};
const manageReportSub = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/common/manage-report-sub.vue'))
  }, 'guide')
};
const manageStaff = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/manage-staff.vue'))
  }, 'guide')
};
const manageStaffSub = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/common/manage-staff-sub.vue'))
  }, 'guide')
};
const manageLimit = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/manage-limit.vue'))
  }, 'guide')
};
const manageLimitSub = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/common/manage-limit-sub.vue'))
  }, 'guide')
};
const managePhone = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/manage-phone.vue'))
  }, 'guide')
};
const manageManager = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/manage-manager.vue'))
  }, 'guide')
};
// 司机问题
const driverContact = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/driver-contact.vue'))
  }, 'guide')
};
const driverCollect = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/driver-collect.vue'))
  }, 'guide')
};
const driverGrade = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/driver-grade.vue'))
  }, 'guide')
};
const paymentWhat = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/payment-what.vue'))
  }, 'guide')
};
const paymentApply = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/payment-apply.vue'))
  }, 'guide')
};
const paymentPay = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/payment-pay.vue'))
  }, 'guide')
};
const paymnetRepay = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/payment-repay.vue'))
  }, 'guide')
};
const paymentDeposit = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/payment-deposit.vue'))
  }, 'guide')
};
const paymentQuestion = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/guide/payment-question.vue'))
  }, 'guide')
};

export default [
  {
    path: '/guide',
    name: 'guide',
    component: main,
    children: [
      {
        path: '/guide/index',
        name: 'guideIndex',
        component: index,
        meta: {title: '新手指引'}
      }, {
        path: '/guide/order-now',
        name: 'guideOrderNow',
        component: orderNow,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/order-future',
        name: 'guideOrderFuture',
        component: orderFuture,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/order-cancel',
        name: 'guideOrderCancel',
        component: orderCancel,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/fee-mode',
        name: 'guideFeeMode',
        component: feeMode,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/fee-carry',
        name: 'guideFeeCarry',
        component: feeCarry,
        children: [
          {
            path: '/guide/fee-carry/:index',
            name: 'guideFeeCarrySub',
            component: feeCarrySub,
            meta: {title: '使用手册'}
          }
        ]
      }, {
        path: '/guide/fee-pay',
        name: 'guideFeePay',
        component: feePay,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/fee-complain',
        name: 'guideFeeComplain',
        component: feeComplain,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/fee-calculator',
        name: 'guideFeeCalculator',
        component: feeCalculator,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/fee-standard',
        name: 'guideFeeStandard',
        component: feeStandard,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/fee-charge',
        name: 'guideFeeCharge',
        component: feeCharge,
        children: [
          {
            path: '/guide/fee-charge/:index',
            name: 'guideFeeChargeSub',
            component: feeChargeSub,
            meta: {title: '使用手册'}
          }
        ]
      }, {
        path: '/guide/invoice-electron',
        name: 'guideInvoiceElectron',
        component: invoiceElectron,
        children: [
          {
            path: '/guide/invoice-electron/:index',
            name: 'guideInvoiceElectronSub',
            component: invoiceElectronSub,
            meta: {title: '使用手册'}
          }
        ]
      }, {
        path: '/guide/invoice-paper',
        name: 'guideInvoicePaper',
        component: invoicePaper,
        children: [
          {
            path: '/guide/invoice-paper/:index',
            name: 'guideInvoicePaperSub',
            component: invoicePaperSub,
            meta: {title: '使用手册'}
          }
        ]
      }, {
        path: '/guide/invoice-apply',
        name: 'guideInvoiceApply',
        component: invoiceApply,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/invoice-aptitude',
        name: 'guideInvoiceAptitude',
        component: invoiceAptitude,
        children: [
          {
            path: '/guide/invoice-aptitude/:index',
            name: 'guideInvoiceAptitudeSub',
            component: invoiceAptitudeSub,
            meta: {title: '使用手册'}
          }
        ]
      }, {
        path: '/guide/invoice-info',
        name: 'guideInvoiceInfo',
        component: invoiceInfo,
        children: [
          {
            path: '/guide/invoice-info/:index',
            name: 'guideInvoiceInfoSub',
            component: invoiceInfoSub,
            meta: {title: '使用手册'}
          }
        ]
      }, {
        path: '/guide/policy-service',
        name: 'guidePolicyService',
        component: policyService,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/policy-online',
        name: 'guidePolicyOnline',
        component: policyOnline,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/manage-info',
        name: 'guideManageInfo',
        component: manageInfo,
        children: [
          {
            path: '/guide/manage-info/:index',
            name: 'guideManageInfoSub',
            component: manageInfoSub,
            meta: {title: '使用手册'}
          }
        ]
      }, {
        path: '/guide/manage-intro',
        name: 'guideManageIntro',
        component: manageIntro,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/manage-child-depart',
        name: 'guideManageChildDepart',
        component: manageChildDepart,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/manage-depart-manager',
        name: 'guideManageDepartManager',
        component: manageDepartManager,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/manage-report',
        name: 'guideManageReport',
        component: manageReport,
        children: [
          {
            path: '/guide/manage-report/:index',
            name: 'guideManageReportSub',
            component: manageReportSub,
            meta: {title: '使用手册'}
          }
        ]
      }, {
        path: '/guide/manage-staff',
        name: 'guideManageStaff',
        component: manageStaff,
        children: [
          {
            path: '/guide/manage-staff/:index',
            name: 'guideManageStaffSub',
            component: manageStaffSub,
            meta: {title: '使用手册'}
          }
        ]
      }, {
        path: '/guide/manage-limit',
        name: 'guideManageLimit',
        component: manageLimit,
        children: [
          {
            path: '/guide/manage-limit/:index',
            name: 'guideManageLimitSub',
            component: manageLimitSub,
            meta: {title: '使用手册'}
          }
        ]
      }, {
        path: '/guide/manage-phone',
        name: 'guideManagePhone',
        component: managePhone,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/manage-manager',
        name: 'guideManageManager',
        component: manageManager,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/driver-contact',
        name: 'guideDriverContact',
        component: driverContact,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/driver-collect',
        name: 'guideDriverCollect',
        component: driverCollect,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/driver-grade',
        name: 'guideDriverGrade',
        component: driverGrade,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/payment-what',
        name: 'guidePaymentWhat',
        component: paymentWhat,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/payment-apply',
        name: 'guidePaymentApply',
        component: paymentApply,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/payment-pay',
        name: 'guidePaymentPay',
        component: paymentPay,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/payment-repay',
        name: 'guidePaymnetRepay',
        component: paymnetRepay,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/payment-deposit',
        name: 'guidePaymentDeposit',
        component: paymentDeposit,
        meta: {title: '使用手册'}
      }, {
        path: '/guide/payment-question',
        name: 'guidePaymentQuestion',
        component: paymentQuestion,
        meta: {title: '使用手册'}
      }, {
        path: '*',
        redirect: {name: 'guideIndex'}
      }
    ]
  }
]
