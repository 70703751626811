const notFind = resolve => {
  require.ensure([], () => {
      resolve(require('../../components/common/error.vue'))
  }, 'order-share')
};
export default [{
  path: '*',
  component: notFind,
  meta: {
      title: '404页面'
  }
}
]