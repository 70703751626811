<template>
  <div :class="['hll-button', `hll-button-${type}`]" @click="handleClick">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'HllButton',
  props: {
    type: {
      type: String,
      default: 'default',
    },
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.hll-button {
  padding: 13px;

  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  text-align: CENTER;
  line-height: 22px;
  transition: all 0.2s ease-out;
}

.hll-button-default {
  background: #ffffff;
  border: 1px solid #d8deeb;
  border-radius: 8px;
  box-shadow: 0px 3px 0px 0px rgba(192, 198, 209, 0.1);
  font-size: 16px;
  font-weight: 600;
  color: #454c66;
}

.hll-button-primary {
  color: #ffffff;
  background: #3377ff;
  box-shadow: 0px 3px 0px 0px rgba(51, 119, 255, 0.1);
}

.hll-button-plain {
  font-weight: 500;
  color: #454c66;
  background: #f5f7fa;
}

.hll-button-warning {
  color: #ffffff;
  background: #f23041;
  box-shadow: 0px 3px 0px 0px rgba(51, 119, 255, 0.1);
}

.hll-button:active {
  opacity: 0.7;
}
</style>
