//按需加载模块
const Ad = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/m-intro2/ad.vue'))
  }, 'm-intro2-ad')
}

const Intro = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/m-intro2/intro.vue'))
  }, 'm-intro2')
}

const Consult = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/m-intro2/consult.vue'))
  }, 'm-intro2')
}

const ConsultSuccess = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/m-intro2/consult-success.vue'))
  }, 'm-intro2')
}

const mpIndex = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/m-intro2/mp-index.vue'))
  }, 'm-intro2')
}

//前端路由
export default [
  {path: '/intro', component: Intro, meta: {title: '货拉拉企业版'}},
  {path: '/intro-consult', component: Consult, meta: {title: '立即咨询'}},
  {path: '/intro-consult-success', component: ConsultSuccess, meta: {title: '立即咨询'}},
  {path: '/ad', component: Ad, meta: {title: '货拉拉企业版'}},
  {path: '/mp-index', component: mpIndex, meta: {title: '定制城配'}},
]
