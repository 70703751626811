// 充值中心
//state
const state = {
  controlRecharge: 0, // 充值灰度控制 {0:否,1:是}
  rechargeAmountFen: 0,
  presentAmountFen: 0,
  voucherAmountFen: 0,
}

// getters
const getters = {}

// actions
const actions = {

}

// mutations
const mutations = {
  setControlRecharge(state, value) {
    state.controlRecharge = value
  },
  setRechange(state, type, value) {
    state[type] = value;
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
