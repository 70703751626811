// 企业注销相关
const eplogoutApply = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/ep-logout/apply.vue'));
    },
    'epLogout',
  );
};
const epLogoutCaptcha = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/ep-logout/captcha.vue'));
    },
    'epLogout',
  );
};
const eplogoutConfirm = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/ep-logout/confirm.vue'));
    },
    'epLogout',
  );
};
const eplogoutResult = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/ep-logout/result.vue'));
    },
    'epLogout',
  );
};

export default [
  {
    path: '/eplogout/apply',
    name: 'epLogoutApply',
    component: eplogoutApply,
    meta: { title: '注销企业' },
  },
  {
    path: '/eplogout/captcha',
    name: 'epLogoutCaptcha',
    component: epLogoutCaptcha,
    meta: { title: '注销企业' },
  },
  {
    path: '/eplogout/confirm',
    name: 'epLogoutConfirm',
    component: eplogoutConfirm,
    meta: { title: '注销企业' },
  },
  {
    path: '/eplogout/result',
    name: 'epLogoutResult',
    component: eplogoutResult,
    meta: { title: '注销企业' },
  },
];
