// 账单申诉
const Contract = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/contract/preview.vue'))
  }, 'contract')
};

// 前端路由
export default [
  {
    path: '/contract/preview',
    name: 'contract',
    component: Contract,
    meta: {title: '合同签订'}
  },
  {
    path: '/contract/only-preview',
    name: 'contract',
    component: Contract,
    meta: {title: '合同预览'}
  }
]
