//按需加载模块
const CancelOrder = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/cancel-order/index.vue'))
  }, 'cancel-order')
}
const CancelOrderRules = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/cancel-order/rules.vue'))
  }, 'cancel-order')
}
const CancelOrderManage = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/cancel-order/manage.vue'))
  }, 'cancel-order')
}

//前端路由
export default [
  {path: '/cancel-order', component: CancelOrder, meta: {title: '取消订单'}},
  {path: '/cancel-order-rules', component: CancelOrderRules, meta: {title: '货运订单取消规则'}},
  {path: '/cancel-order-manage', component: CancelOrderManage, meta: {title: '取消订单'}},
]
