// 格式化手机号
export function formatTel(value) {
  if (!value) return '';
  
  return value.replace(/^(\d{3})(\d{4})(\d{4})$/g, '$1 $2 $3');
}

// 手机号遮罩
export function maskPhone(value) {
  if (!value) return '';

  return value.replace(/^(\d{3})(\d{4})(\d{4})$/g, '$1****$3');
}