//按需加载模块
const mpIndex = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/mp/index.vue'))
  }, 'mp')//这个参数一样的模块，会被打包在一个文件中
}
const mpInfoFill = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/mp/info-fill.vue'))
  }, 'mp')//这个参数一样的模块，会被打包在一个文件中
}
const mpSuccess = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/mp/success.vue'))
  }, 'mp')//这个参数一样的模块，会被打包在一个文件中
}
//前端路由
export default [
  // 静态页面 - 通过eops配置生成静态页面的模板 - 勿删
  {path: '/mp/index', component: mpIndex, meta: {title: '定制城配'}},
  {path: '/mp/info-fill', component: mpInfoFill, meta: {title: '信息填写'}},
  {path: '/mp/success', component: mpSuccess, meta: {title: '信息填写'}},
]
