// 按需加载模块
const cnuserPrice = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/cnuser-price/cnuser-price.vue'));
    },
    'cnuser-price'
  );
};
const priceCalculator = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/cnuser-price/price-calculator.vue'));
    },
    'cnuser-price'
  );
};

// 前端路由
export default [
  {
    path: '/cnuser-price',
    name: 'cnuserPrice',
    component: cnuserPrice,
    meta: { title: '货运收费标准' }
  },
  {
    path: '/price-calculator',
    component: priceCalculator,
    meta: { title: '价格计算器' }
  }
];
