const LandingPage = resolve => {
    require.ensure([], () => {
        resolve(require('../../components/landing-page/home'))
    }, 'landing-home')
  };
const LandingPageOnlineCoustomer = resolve => {
    require.ensure([], () => {
        resolve(require('../../components/landing-page/component/online-customer'))
    },'landing-customer')
}
export default [
    {
        path: '/landing-page',
        component: LandingPage,
        meta: {
            title: '货拉拉企业版'
        }
    },
    {
        path: '/landing-page/customer',
        component: LandingPageOnlineCoustomer
    }
]