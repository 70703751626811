import '@babel/polyfill'; //解决安卓4.X兼容性问题
import Vue from 'vue';
import sa from 'sa-sdk-javascript';
import App from './App.vue';
import HllUI from 'hll-m-ui'; // UI组件库
import Store from './store'; // 使用vuex
import Axios from './axios'; // 使用axios和mock
import Router from './router'; // 使用vue-router
import Directive from './directive'; // 自定义指令
import Mixin from './mixin'; // 自定义指令
import Config from './config'; // 自定义指令
import './services/filters'; // 过滤器
import './services/hll-ui'; // hll-ui
import NativeInterface from './tools/nativeInterface';
import watchdog from '@hll/watch-dog-jsutil';
import { initWatchdog } from '@/services/watch-dog';
import probes from '@hll/sec-sign-sdk';
import { eapiUrl } from '@/config'
import baiduMapMixin from './mixins/baidu-map-mixin';
import { isEapp, isWx } from '@hll/eapp-utils';
import driverRoleText from '@/services/driver-role-text';


//引入公共样式
import './assets/css/public.scss';
import './assets/css/hairline.scss';
// 引入iconfont
import './assets/css/iconfont.css';
import Hllfooter from './components/common/footer.vue'; //热线

import { tools } from './tools/index';

if(['localhost', '172.', '127.', '-dev', '-stg', '-pre', '-gra'].includes(location.hostname)) {
  Vue.config.devtools = true;
}
import { coolmage } from '@hll/coolmage';
import { getQueryString } from '@hll/eapp-utils';
const showDebbuger = getQueryString('probe_debbuger') === '1' || localStorage.getItem('probe_debbuger') === '1';

// 签名sdk
probes.init({
  allowList: ['eapi.huolala.cn', 'eapi-stg.huolala.cn', 'eapi-pre.huolala.cn'],
  debug: showDebbuger,
});

// 初始化watchdog
initWatchdog();

Vue.component('hll-footer',Hllfooter);
Vue.use(HllUI);
Vue.use(Store);
Vue.use(Router);
Vue.use(Directive);
Vue.use(Mixin);
Vue.use(baiduMapMixin);
Vue.use(Config);
Vue.use(NativeInterface);
Vue.use(driverRoleText); // 设置骑手/司机文案
Vue.prototype.$coolmage = coolmage


// 白名单处理未登陆被拦截跳登陆问题
const {hash} = window.location;
let noLogin = false;
if(hash.includes("#/account-period")){
  noLogin = true
}

// 优先获取token
let ua = undefined;
// if (/localhost|^172/.test(location.hostname)) {
//   ua = 'huolala(3114,android,,3.1.14,vivo X6A,86896602662419013,e55b0db1c9c8495289ef8e32f7cf1ba0)';
// }
tools.getAppInfo(ua).then(token => {
  if(token) {
    Store.store.commit('saveToken', token);
    Vue.use(Axios, token, { mock: false }, axios => {
      try {
        axios.post(`${eapiUrl}/index.php?_m=user&_a=info`, {token: token}, {noLogin} ).then(res => {
          if (res.data.ret === 0) {
            const data = res.data.data;
            // 埋点登录，埋点系统可追踪user_id
            sa.login(data.user_fid);
            sa.registerPage({})
            tools.setCookie('user_fid', data.user_fid);

            Store.store.commit('saveUserInfo', data);
            watchdog.setConfig({
              bizUserId: data.user_fid,
              bizUserType: 'eappweb_uid', // 仅用于开发者自己区分，bizUserId 实际代表了什么
            });
          }
        });
      } catch (e) {
        console.error('watchdog getuserinfo error')
      }
      // 部分无登录态页面会有非企业token，调企业版本信息接口会报statusCode400（正常报200没问题，后端不好改网关，改了全要）
      // 这类页面不调接口。
      const noLoginStatusPageRoutesList = [
        '/launch',
        '/intro',
      ] 
      const isNoLoginStatusPage = noLoginStatusPageRoutesList.every(function (item) {
        return location.hash.indexOf(item) === -1
      });
      // 企业端内调此接口
      if (isNoLoginStatusPage || isEapp() || isWx() ) {
        // 企业版本信息
        try {
          axios.post(`${eapiUrl}/eucApi?name=ep.version.getInfoByEpId`, {
            name: 'ep.version.getInfoByEpId',
            data: {
              os: 'wxa',
              equityFlag: 1,
            },
          },{
            headers: {
              'hll-appid': 'bfe-euc-svc',
              'Content-Type': 'application/json',
            },
          }).then(res => {
            if (res.data.ret === 0) {
              console.log(res.data.data);
              Store.store.commit('setInfoByEpId', res.data.data);
            }
          }).catch(e => {
            console.log(e);
          })
        } catch (e) {
          console.error(e)
        }
      }


    });
  } else {
    Vue.use(Axios, token, { mock: false }, null);
  }
  new Vue({
    el: '#app',
    render: h => h(App),
    router: Router.router,
    store: Store.store
  });

});
