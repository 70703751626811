import { myConsole } from '@/services/watch-dog';


// 
/**
 * eappweb嵌套在iframe中，与main frame通信，每次收到消息后会自动移除监听
*/
// 每次监听后移除监听方法
export default class MessageListener {
  // 添加监听器的方法，每次触发后自动移除
  addListener(callback) {
    // 定义处理函数，并在处理完成后自动移除监听器
    const handler = (event) => {
      try {
        callback(event);
      } finally {
        // 自动移除监听器
        window.removeEventListener('message', handler);
        myConsole.log('message 事件监听器已移除');
      }
    };

    // 添加监听器
    window.addEventListener('message', handler);
    myConsole.log('message 事件监听器已添加');
  }
}
