import { isEapp } from "@hll/eapp-utils"

//state
const state = {
  os:'',
  device_id:'',
  device_type:'',
  version:'',
  billboard: null,
  epVersion: 0, // 1 ：品牌版 2 ：经济版 0：自选版本 3：未设置 4 ：定制品牌版 5： 品牌试用版
  uiVersion: 0, // ui版本 0: 非经济版， 1: 经济版

  /**
   * 企业版本信息
   * nitialVersion	integer		初始版本
   * current_version	integer		当前版本(0:自选;1:品牌版;2:经济版;3:未设置)
   * default_version	integer		默认版本(1:品牌版;2:经济版)
   * selfSelecteOrNot	integer		是否自选版本(1:是;0:否)
   * equity_package_type	string		权益包类型(1:月结账户；2:企业管理；3:下单管理；4:运力保障)
   * updated_at	localdatetime		更新时间
   * created_at	localdatetime		创建时间
   * ep_version_is_display	integer		版本介绍页是否已展示(1:是;0:否)
   * expire_time	localdatetime		版本到期时间
   * trial_start_time	localdatetime		试用版开始时间
   * customer_type	integer		1-注册新客 2-老客
   * newVersionFlag	integer		0,1:老版本APP，2:新版APP
   * epEquityList	array
   * modeType Integer		运营模式，0：承运，1：撮合
   * openClearAccount Integer  是否开通清分登记账户。0：未开通，1：处理中，2：已开通
   */
  infoByEpId: {
    modeType: 0,
  },
  queryParams: {},
  epFrom: null,
}

// getters
const getters = {
  epVersion: (state) => {
    return state.epVersion
  },
  uiVersion: (state) => {
    return state.uiVersion
  },
  infoByEpId: (state) => {
    return state.infoByEpId
  },
  queryParams: (state) => {
    return state.queryParams ||{};
  },
  isMatchType: state => state.infoByEpId?.modeType === 1,
  isClearType: state => state.infoByEpId?.isClearRequire === 1,
  getModeCheck: state => ({ clearType, matchType }) => {
    return +state.infoByEpId?.isClearRequire === +clearType && +state.infoByEpId?.modeType === +matchType;
  },
  getEpFrom: (state) => state.epFrom,
  isEpHarmony: (state) => state.epFrom === 'ep_harmony',
  // 企业app且不是鸿蒙
  isEappButNotHarmony: (_, getters) => isEapp() && !getters.isEpHarmony,
  // 是否是企业H5
  isEpH5: (state) => state.epFrom === 'ep_h5',
}

// actions
const actions = {}

// mutations
const mutations = {
  updateBillboard(state, payload) {
    state.billboard = payload;
  },
  setEpVersion(state, payload = 0) {
    state.epVersion = payload;
  },
  setUiVersion(state, payload = 0) {
    state.uiVersion = payload;
  },
  setInfoByEpId(state, payload) {
    state.infoByEpId = payload;
  },
  setQueryParams(state, payload) {
    state.queryParams = payload;
  },
  setEpFrom(state, payload) {
    state.epFrom = payload;
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}


