<template>
  <section>
    <div class="hotline">
      <a v-bind:href="isIPhone ? 'tel:'+phoneNo : 'javascript:;'" @click="callAndroid" class="hl_tel"><i class="iconfont icon-ic_telephone"></i>&nbsp;&nbsp;客服热线&nbsp;&nbsp;{{phoneNo}}</a>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'PhoneButton',
    data() {
      return {
        isIPhone: true,
        phoneNo: '4008783636'
      }
    },
    mounted() {
      this.isIPhone = /IOS/i.test(navigator.userAgent);
    },
    methods: {
      callAndroid() {
        if (!this.isIPhone && app && app.webcall) {
          app.webcall(JSON.stringify({
            action: 'callPhone',
            phone_no: ''+this.phoneNo+''
          }));
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.hotline{
  color: rgba(66,68,86,1);
  font-size: 12px;
  text-align: center;
  padding: 34px 0 12px 0;
  .hl_tel{
    vertical-align: middle;
    height: 14px;
    line-height: 14px;
    & i{
      font-size: 13px;
    }
  }
}
</style>