import VueRouter from 'vue-router';
import routes from './routes';

// 创建路由实例
const router = new VueRouter({
  routes,
});

// 全局路由器钩子，跳转之前
router.beforeEach((to, from, next) => {
  // 通过路由判断设置跳转后重置滚动值
  let view = document.getElementsByClassName('reset-top-scroll');
  if (view.length) {
    view[0].scrollTop = 0;
  }

  // 修改页面标题
  // 由于ios在监听页面变化时有bug，页面title没变化时不会出现左上角的关闭按钮，先将title置空 setTimeout中重新设置下title
  if (to.meta && to.meta.title) {
    document.title = '';
    setTimeout(() => {
      document.title = to.meta.title;
    });
  } else if (to.query && to.query.doc_title) {
    document.title = to.query.doc_title;
  }

  // 设置背景色
  if (to.meta && to.meta.bgColor) {
    document.body.style.backgroundColor = to.meta.bgColor;
  } else {
    document.body.style.backgroundColor = '';
  }

  next(); // 必须调用next
});

// 初始化
function install(Vue) {
  // 使用VueRouter插件
  Vue.use(VueRouter);
}

export default {
  install,
  router, // 把router实例传递出去，用于Vue实例化方法的参数
};
