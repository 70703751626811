const EpWxa = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/ep-wxa/index'));
    },
    'ep-wxa',
  );
};
export default [
  {
    path: '/ep-wxa',
    component: EpWxa,
    meta: {
      title: '',
    },
  },
];
