// 月结、保证金相关

// 月结申请
const paymentApply = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/payment/apply.vue'));
    },
    'payment'
  );
};
// 月结申请-保证金模式
const paymentApplyDeposit = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/payment/apply-deposit.vue'));
    },
    'payment'
  );
};
// 月结申请-保证金模式-成功
const paymentDepositResult = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/payment/apply-deposit-result.vue'));
    },
    'payment'
  );
};
// 月结申请-额度审批模式
const paymentApplyCredit = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/payment/apply-credit.vue'));
    },
    'payment'
  );
};
// 月结申请-额度审批结果
const paymentCreditResult = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/payment/apply-credit-result.vue'));
    },
    'payment'
  );
};
// 月结申请-保证金模式与额度审批模式区别
const paymentApplyDescribe = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/payment/describe.vue'));
    },
    'payment'
  );
};
// 企业授权
const paymentAuth = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/payment/auth.vue'));
    },
    'payment'
  );
};
// 企业授权-获取电子授权书
const paymentAuthElectron = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/payment/auth-electron.vue'));
    },
    'payment'
  );
};
// 企业授权-提交企业授权书
const paymentAuthSubmit = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/payment/auth-submit.vue'));
    },
    'payment'
  );
};
// 保证金管理
const depositManage = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/payment/deposit-manage.vue'));
    },
    'payment'
  );
};
// 保证金转入 说明页
const depositRepay = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/payment/deposit-repay.vue'));
    },
    'payment'
  );
};
// 保证金转入 表单提交页
const depositSubmit = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/payment/deposit-submit.vue'));
    },
    'payment'
  );
};
// 还款 说明页
const repayFlow = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/payment/repay-flow.vue'));
    },
    'payment'
  );
};
// 还款 表单提交页
const repaySubmit = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/payment/repay-submit.vue'));
    },
    'payment'
  );
};
// 提交还款成功
const repaySuccess = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/payment/repay-success.vue'));
    },
    'payment'
  );
};
// 示例页面
const paymentExample = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/payment/example.vue'));
    },
    'payment'
  );
};

// 申请月结新入口
const paymentMain = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/payment/index.vue'));
    },
    'payment'
  );
};

// 线下还款记录表
const paymentRecord = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/payment/repay-record.vue'));
    },
    'payment'
  );
};

// 前端路由
export default [
  {
    path: '/payment/example/:example',
    name: 'paymentExample',
    component: paymentExample,
    meta: {
      title: '查看示例'
    }
  },
  {
    path: '/payment/auth',
    component: paymentAuth,
    meta: {
      title: '企业授权'
    }
  },
  {
    path: '/payment/auth-electron',
    component: paymentAuthElectron,
    meta: {
      title: '获取电子授权书'
    }
  },
  {
    path: '/payment/auth-submit',
    name: 'paymentAuthSubmit',
    component: paymentAuthSubmit,
    meta: {
      title: '提交企业授权书'
    }
  },
  {
    path: '/payment/apply',
    component: paymentApply,
    meta: {
      title: '月结申请'
    }
  },
  {
    path: '/payment/apply-deposit',
    name: 'paymentApplyDeposit',
    component: paymentApplyDeposit,
    meta: {
      title: '保证金模式'
    }
  },
  {
    path: '/payment/deposit-result',
    component: paymentDepositResult,
    meta: {
      title: '月结申请结果'
    }
  },
  {
    path: '/payment/apply-credit',
    name: 'paymentApplyCredit',
    component: paymentApplyCredit,
    meta: {
      title: '额度审批模式'
    }
  },
  {
    path: '/payment/credit-result',
    component: paymentCreditResult,
    meta: {
      title: '月结申请结果'
    }
  },
  {
    path: '/payment/describe',
    component: paymentApplyDescribe,
    meta: {
      title: '保证金模式'
    }
  },
  {
    path: '/payment/deposit-manage',
    component: depositManage,
    meta: {
      title: '保证金管理'
    }
  },
  {
    path: '/payment/pay-deposit',
    component: depositRepay,
    meta: {
      title: '保证金转入'
    }
  },
  {
    path: '/payment/repay/submit',
    name: 'paymentRepaySubmit',
    component: depositSubmit,
    meta: {
      title: '提交汇款信息'
    }
  },
  {
    path: '/payment/repay',
    name: 'repaySubmit',
    component: repaySubmit,
    meta: {
      title: '还款'
    }
  },
  {
    path: '/payment/repay-flow',
    name: 'repayFlow',
    component: repayFlow,
    meta: {
      title: '还款方法'
    }
  },
  {
    path: '/payment/repay/success',
    component: repaySuccess,
    meta: {
      title: '提交汇款信息'
    }
  },
  {
    path: '/payment/index',
    component: paymentMain,
    meta: {
      title: '申请月结'
    }
  },
  {
    path: '/payment/record',
    component: paymentRecord,
    meta: {
      title: '线下还款记录'
    }
  }
];
