/*
 * @Descripttion: 费用明细路由
 * @Author: jungle.huang
 * @Date: 2021-04-21 13:58:04
 * @LastEditors: jungle.huang
 * @LastEditTime: 2021-04-27 14:18:07
 */
const expenseDetails = (reslove) => {
  require.ensure(
    [],
    () => {
      reslove(require('@/components/expense-details/index.vue'));
    },
    'expense-details',
  );
};

const familiarExpenseDetails = (reslove) => {
  require.ensure(
    [],
    () => {
      reslove(require('@/components/expense-details/familiar.vue'));
    },
    'familiar-expense-details',
  );
};

export default [
  {
    path: '/expense-details',
    name: 'expense-details',
    component: expenseDetails,
    meta: { title: '费用明细' },
  },
  {
    path: '/familiar-expense-details',
    name: 'familiar-expense-details',
    component: familiarExpenseDetails,
    meta: { title: '费用明细' },
  },
];
