//按需加载模块
const Integration = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/integration/index.vue'))
  }, 'integration')
}

//前端路由
export default [
  {path: '/integration', component: Integration, meta: {title: '货拉拉'}}, // 积分活动
]
