// 公告 协议更新展示
const noticeNew = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/notice-new/notice-new.vue'))
  }, 'notice-new')
};
//《货拉拉企业版网络货运服务协议》更新公告
const noticeUpdateFreightClause = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/notice-new/notice-update-freight-clause.vue'))
  }, 'notice-update-freight-clause')
}
// 货拉拉企业版信息服务协议
const clause = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/notice-new/clause.vue'))
  }, 'notice-new')
};
// 货拉拉知识产权协议
const equityProtocol = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/notice-new/equity-protocol.vue'))
  }, 'notice-new')
};
// 货拉拉禁限托运货物条款
const protocolText = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/notice-new/protocol-text.vue'))
  }, 'notice-new')
};
// 用户行为规范
const behaviorStandard = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/notice-new/behavior-standard.vue'))
  }, 'notice-new')
};
// 货物托运服务协议
const protocol = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/notice-new/protocol.vue'))
  }, 'notice-new')
};
// 充值协议
const chargeAgreement = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/notice-new/charge-agreement.vue'))
  }, 'notice-new')
};
// 月结协议
const paymentAgreement = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/notice-new/payment-agreement.vue'))
  }, 'notice-new')
};
// 禁限运输货物条款更新&公示
const prohibitedTransportAgreement = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/notice-new/prohibited-transport-agreement'))
  }, 'notice-new')
};

// 前端路由
export default [
  {
    path: '/notice-new',
    component: noticeNew,
    meta: {title: '货拉拉企业版信息公告'}
  },
  {
    path: '/notice-update-freight-clause',
    component: noticeUpdateFreightClause,
    meta: {
      title: '《货拉拉企业版网络货运服务协议》更新公告'
    }
  },
  {
    path: '/notice-new/clause',
    component: clause,
    meta: {title: '货拉拉企业版网络货运服务协议'}
  },
  {
    path: '/notice-new/behavior-standard',
    component: behaviorStandard,
    meta: {title: '用户行为规范'}
  },
  {
    path: '/notice-new/equity-protocol',
    component: equityProtocol,
    meta: {title: '货拉拉知识产权条款'}
  },
  {
    path: '/notice-new/protocol',
    component: protocol,
    meta: {title: '货物托运服务协议'}
  },
  {
    path: '/notice-new/protocol-text',
    component: protocolText,
    meta: {title: '货拉拉禁限运输货物条款'}
  },
  {
    path: '/notice-new/charge-agreement',
    name: 'chargeAgreement',
    component: chargeAgreement,
    meta: {title: '充值协议'}
  },
  {
    path: '/notice-new/payment-agreement',
    name: 'paymentAgreement',
    component: paymentAgreement,
    meta: {title: '货拉拉企业版账期协议'}
  },
  {
    path: '/notice-new/prohibited-transport-agreement',
    name: 'prohibitedTransportAgreement',
    component: prohibitedTransportAgreement,
    meta: { title: '禁限运输货物条款更新&公示' },
  }
]
