const state = {
  data:{
    order_uuid:'',
    price:0,
    distance:0,
    pay_title:'',
    list:[],
    driver_name:'',
    driver_license_plate:'',
    name:''
  }
}

// getters
const getters = {

}

// actions
const actions = {

}

// mutations
const mutations = {
  setOrderPrice(state,value){
    state.data = value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}


