import './promise';

/**
 * 由于线上和开发环境的 ak 不一样（新 ak 会校验 referer 的 host，所以本地开发沿用旧 ak）
 * 注意事项: 线上和开发环境的ak 使用v3版本时 定位功能会超时。目前使用v2 2022-11-03
 * 文档参考
 * 1. https://mapopen-pub-jsapi.bj.bcebos.com/jsapi/reference/jsapi_reference.html
 * 2. https://lbsyun.baidu.com/index.php?title=jspopular/guide/geolocation
 */
// 百度地图ak
import watchdog from '@hll/watch-dog-jsutil';
const vanEnv = document?.documentElement?.dataset?.vanEnv;
export const baiduMapAk = vanEnv ? 'TgFdVsihtPGq3D94dKBsAz0FZVpo5e3P' : 'GANo2ajua2rTiTTc05oKIrYr';
/**
 * 地址转换 上面的只取第一个不支持多个
 * @param coords 114.21892734521,29.575429778924 需转换的源坐标，多组坐标以“；”分隔（经度lon，纬度lat）
 * @param from 1：GPS标准坐标（wgs84）； 3：火星坐标（gcj02）; 5：百度地图采用的经纬度坐标（bd09ll）；
 * @param to 3：火星坐标（gcj02），即高德地图、腾讯地图及MapABC等地图使用的坐标； 5：百度地图采用的经纬度坐标（bd09ll）；
 * @returns [BMap.Point(lat,lon),,,]
 */
function getGeoconvMultiterm(coords, from = 1, to = 5) {
  return new Promise((resolve, reject) => {
    const convertor = new BMap.Convertor();
    convertor.translate(coords, from, to, (data) => {
      if (data && data.status === 0) {
        resolve(data.points || []);
      } else {
        reject(data);
      }
    });
  });
}

/**
 * 坐标转换 gcj 转 bd
 * @param lat 纬度 29.575429778924
 * @param lon 经度 114.21892734521
 * @returns [lat, lon]
 */
function gcj2bd(lat, lon) {
  var x = lon,
    y = lat;
  var x_pi = (3.14159265358979324 * 3000.0) / 180.0;
  var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
  var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
  var bd_lon = z * Math.cos(theta) + 0.0065;
  var bd_lat = z * Math.sin(theta) + 0.006;
  var result = [];
  result.push(bd_lat);
  result.push(bd_lon);
  return result;
}

/**
 * 坐标转换 wgs 转 bd
 * @param lat 纬度 29.575429778924
 * @param lon 经度 114.21892734521
 * @returns [lat, lon]
 */
const wgs2bd = function (lat, lon) {
  var pi = 3.14159265358979324;
  var a = 6378245.0;
  var ee = 0.00669342162296594323;
  var x_pi = (3.14159265358979324 * 3000.0) / 180.0;

  lat = parseFloat(lat);
  lon = parseFloat(lon);
  var wgs2gcjR = wgs2gcj(lat, lon);
  var gcj2bdR = gcj2bd(wgs2gcjR[0], wgs2gcjR[1]);
  return gcj2bdR;

  function gcj2bd(lat, lon) {
    var x = lon,
      y = lat;
    var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
    var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
    var bd_lon = z * Math.cos(theta) + 0.0065;
    var bd_lat = z * Math.sin(theta) + 0.006;
    var result = [];
    result.push(bd_lat);
    result.push(bd_lon);
    return result;
  }

  function wgs2gcj(lat, lon) {
    var dLat = transformLat(lon - 105.0, lat - 35.0);
    var dLon = transformLon(lon - 105.0, lat - 35.0);
    var radLat = (lat / 180.0) * pi;
    var magic = Math.sin(radLat);
    magic = 1 - ee * magic * magic;
    var sqrtMagic = Math.sqrt(magic);
    dLat = (dLat * 180.0) / (((a * (1 - ee)) / (magic * sqrtMagic)) * pi);
    dLon = (dLon * 180.0) / ((a / sqrtMagic) * Math.cos(radLat) * pi);
    var mgLat = lat + dLat;
    var mgLon = lon + dLon;
    var result = [];
    result.push(mgLat);
    result.push(mgLon);
    return result;
  }

  function transformLat(lat, lon) {
    var ret =
      -100.0 +
      2.0 * lat +
      3.0 * lon +
      0.2 * lon * lon +
      0.1 * lat * lon +
      0.2 * Math.sqrt(Math.abs(lat));
    ret += ((20.0 * Math.sin(6.0 * lat * pi) + 20.0 * Math.sin(2.0 * lat * pi)) * 2.0) / 3.0;
    ret += ((20.0 * Math.sin(lon * pi) + 40.0 * Math.sin((lon / 3.0) * pi)) * 2.0) / 3.0;
    ret += ((160.0 * Math.sin((lon / 12.0) * pi) + 320 * Math.sin((lon * pi) / 30.0)) * 2.0) / 3.0;
    return ret;
  }

  function transformLon(lat, lon) {
    var ret =
      300.0 + lat + 2.0 * lon + 0.1 * lat * lat + 0.1 * lat * lon + 0.1 * Math.sqrt(Math.abs(lat));
    ret += ((20.0 * Math.sin(6.0 * lat * pi) + 20.0 * Math.sin(2.0 * lat * pi)) * 2.0) / 3.0;
    ret += ((20.0 * Math.sin(lat * pi) + 40.0 * Math.sin((lat / 3.0) * pi)) * 2.0) / 3.0;
    ret +=
      ((150.0 * Math.sin((lat / 12.0) * pi) + 300.0 * Math.sin((lat / 30.0) * pi)) * 2.0) / 3.0;
    return ret;
  }
};

/**
 **** 批量调用百度api转换坐标统一处理****
 * batchTransform 批量坐标转换
 * @param coords 114.21892734521,29.575429778924 需转换的源坐标，多组坐标以“；”分隔（经度，纬度）
 * @param returnLatField 返回值中的纬度字段，默认:lat
 * @param returnLonField 返回值中的经度字段，默认:lng
 * @param from 1：GPS标准坐标（wgs84）； 3：火星坐标（gcj02）; 5：百度地图采用的经纬度坐标（bd09ll）；
 * @param to 3：火星坐标（gcj02），即高德地图、腾讯地图及MapABC等地图使用的坐标； 5：百度地图采用的经纬度坐标（bd09ll）
 * @param revealFn 调用api失败时兜底转换函数
 * @returns [{[returnLatField]:29.575429778924,[returnLonField]:114.21892734521}]
 * @description 百度转换api单次请求可批量解析100个坐标，需要将坐标进行分批请求。
 */
const batchTransform = async function ({
  coords,
  revealFn,
  from,
  to,
  returnLatField,
  returnLonField,
}) {
  const coordsArr = coords.split(';');
  // 单次请求可批量解析10个坐标
  const SINGLE_TRANSFORM_MAX = 10;

  // 请求次数
  const requestFrequency = Math.ceil(coordsArr.length / SINGLE_TRANSFORM_MAX);

  // promise集合
  const promises = [];

  // promise分批请求
  for (let currentRF = 0; currentRF < requestFrequency; currentRF++) {
    const sliceArr = coordsArr.slice(
      currentRF * SINGLE_TRANSFORM_MAX,
      (currentRF + 1) * SINGLE_TRANSFORM_MAX,
    );
    const pointsArr = sliceArr.map((e) => {
      const point = e.split(',');
      return new BMap.Point(point[0], point[1]);
    });
    promises.push(getGeoconvMultiterm(pointsArr, from, to));
  }

  const promiseRes = await Promise.allSettled(promises);
  // promise结果处理
  const result = promiseRes.map((p, index) => {
    if (p.status === 'fulfilled') {
      return p.value.map((e) => ({
        [returnLatField]: e.lat,
        [returnLonField]: e.lng,
      }));
    } else {
      // 兜底转换
      const sliceArr = coordsArr.slice(
        index * SINGLE_TRANSFORM_MAX,
        (index + 1) * SINGLE_TRANSFORM_MAX,
      );
      sliceArr.map((e) => {
        const latlonArr = e.split(',');
        const bds = revealFn(latlonArr[0], latlonArr[1]);
        return {
          [returnLatField]: bds.lat,
          [returnLonField]: bds.lon,
        };
      });
    }
  });

  // 二维数组解构
  const arr2 = result.reduce((a, b) => a.concat(b));
  return arr2;
};

/**
 * batchGcjToBd 批量坐标转换：gcg02 to bd09
 * @param coords 114.21892734521,29.575429778924 需转换的源坐标，多组坐标以“；”分隔（经度，纬度）
 * @param returnLatField 返回值中的纬度字段，默认:lat
 * @param returnLonField 返回值中的经度字段，默认:lon
 * @returns [{[returnLatField]:29.575429778924,[returnLonField]:114.21892734521}] eg:[{lat:29.575429778924,lon:114.21892734521}]
 */
export const batchGcjToBd = async function (
  coords,
  returnLatField = 'lat',
  returnLonField = 'lon',
) {
  return batchTransform({
    coords,
    revealFn: gcj2bd,
    from: 3,
    to: 5,
    returnLatField,
    returnLonField,
  });
};

/**
 * batchWgsToBd wgs to bd09
 * @param coords 114.21892734521,29.575429778924 需转换的源坐标，多组坐标以“；”分隔（经度，纬度）
 * @param returnLatField 返回值中的纬度字段，默认:lat
 * @param returnLonField 返回值中的经度字段，默认:lon
 * @returns [{[returnLatField]:29.575429778924,[returnLonField]:114.21892734521}] eg:[{lat:29.575429778924,lon:114.21892734521}]
 */
export const batchWgsToBd = async function (
  coords,
  returnLatField = 'lat',
  returnLonField = 'lon',
) {
  return batchTransform({
    coords,
    revealFn: wgs2bd,
    from: 1,
    to: 5,
    returnLatField,
    returnLonField,
  });
};

/**
 * 异步查看api是否存在
 */
const callApiPreCheck = (apiName, retryTime = 20) => {
  let checkTime = 0;
  let timer;
  return new Promise((resolve, reject) => {
    if (window[apiName]) {
      return resolve();
    }
    timer = setInterval(() => {
      checkTime++;
      if (checkTime >= retryTime) {
        clearInterval(timer);
        return reject('获取api失败' + apiName);
      }
      if (window[apiName]) {
        clearInterval(timer);
        return resolve();
      }
    }, 200);
  });
};

function loadScript(url, params = {}, position = 'body') {
  return new Promise((resolve) => {
    var script = document.createElement('script');
    script.type = 'text/javascript';

    let keys = Object.keys(params);
    if (keys.length > 0) {
      for (const key in params) {
        script.setAttribute(key, params[key]);
      }
    }

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === 'complete' || script.readyState === 'loaded') {
          resolve();
        }
      };
    } else {
      script.onload = function () {
        resolve();
      };
    }
    script.src = url;

    document[position].appendChild(script);
  });
}

const loadBaiduMap = () => {
  return new Promise(async (resolve) => {
    // const scriptName = `//api.map.baidu.com/api?v=2.0&ak=${baiduMapAk}&s=1`;
    const scriptName = `//api.map.baidu.com/getscript?v=2.0&ak=${baiduMapAk}&services=&t=20231026185850`;
    const findScript = document.querySelector(`script[src="${scriptName}"]`);
    if (findScript) {
      return resolve();
    }
    window.HOST_TYPE = '2';
    window.BMap_loadScriptTime = new Date().getTime();
    await loadScript(scriptName);
    resolve();
  });
};

/**
 * 异步加载百度地图api；加载成功时reslove
 */
export const asyncLoadBaiduMap = () => {
  return new Promise(async (resolve, reject) => {
    try {
      if (window.BMap) {
        return resolve();
      }
      await loadBaiduMap();
      await callApiPreCheck('BMap');
      resolve();
    } catch (e) {
      reject();
      console.log('asyncLoadBaiduMap error:', e);
      watchdog.sendEvent('asyncLoadBaiduMap error:', e);
    }
  });
};
