//按需加载模块
const EpDriverApp = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/ep-driver-app/ep-driver-app.vue'))
  }, 'ep-driver-app')
}

//前端路由
export default [
  {path: '/ep-driver', component: EpDriverApp, meta: {title: '企业司机'}},
]
