const MyAccount = () => import('@/components/account/my-account/index.vue');
const CloseAccount = () => import('@/components/account/close-account/index.vue');
const CloseAccountResult = () => import('@/components/account/close-account/result.vue');

export default [
  {
    path: '/my-account',
    component: MyAccount,
    meta: {
      title: '我的账号',
      bgColor: '#f5f7fa',
    }
  },
  {
    path: '/close-account',
    component: CloseAccount,
    meta: {
      title: '注销账号',
      bgColor: '#f5f7fa',
    }
  },
  {
    path: '/close-account/result',
    component: CloseAccountResult,
    meta: {
      title: ' ',
      bgColor: '#f5f7fa',
    }
  },
];
