const UserLandingPage = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/user-landing-page/index'));
    },
    'user-landing'
  );
};

const UserConsult = reslove => {
  require.ensure(
    [],
    () => {
      reslove(require('../../components/user-landing-page/consult'));
    },
    'user-landing'
  );
};

const UserConsultSuccess = reslove => {
  require.ensure(
    [],
    () => {
      reslove(require('../../components/user-landing-page/consult-success'));
    },
    'user-landing'
  );
};
export default [
  {
    path: '/user-landing-page',
    component: UserLandingPage,
    meta: {
      title: '货拉拉企业版'
    }
  },
  {
    path: '/user-landing-page/consult',
    component: UserConsult,
    meta: {
      title: '立即咨询'
    }
  },
  {
    path: '/user-landing-page/consult-success',
    component: UserConsultSuccess,
    meta: {
      title: '立即咨询'
    }
  }
];
