
// 运单号说明
const WaybillInfo = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/waybill/index.vue'));
    },
    'waybill',
  );
};
export default [
  {
    path: '/waybill/info',
    component: WaybillInfo,
    meta: {
      title: '代送物流服务',
    },
  },
];
