import axios from 'axios';
import qs from 'qs';
import Cookies from 'js-cookie';
import mockConfig from './mockConfig';
import HllUI from 'hll-m-ui';
import { tools } from '@/tools';
import { toLoginPageAction } from '@/tools/actions';
import Store from '../store'; //使用vuex
import { eapiUrl } from '@/config'
import { myConsole } from '@/services/watch-dog';
import { getQueryAll } from '@hll/eapp-utils';
import { generateTraceId } from '@hll/trace-id-sdk';

// 生成_su公参
function createSu() {
  return new Date().getTime() + 'ehll' + parseInt(Math.random() * (10000000000 + 1),10);
}

/**
 * 加入header公参数
 * 保留原header头取值逻辑，避免遗漏相关业务参数
 */
const addQueryToRequestHeaders = () => {
  const result = {};
  const urlParams = getQueryAll()
  Object.keys(urlParams).forEach((key) => {
    // 如果是x-hll开头 都补 并且有值
    if (key.startsWith('x-hll')) {
      result[key] = urlParams[key];
    }
  })
  return result;
}

/**
 * 加入header公参数
 * app mock需求：测试环境app传入x-hll-mock-user=xxxx参数，需要加到header中
 */
function addCookiesToRequestHeaders(){
  const result= {};
  const cookies = Cookies.get(); // 获取所有的 cookie
  Object.keys(cookies).forEach((key) => {
    if (key.startsWith('x-hll')) {
      // 如果 key 以 "x-hll" 开头
      result[key] = cookies[key]; // 将它添加到结果中
    }
  });
  return result; // 返回结果
}

//初始化
function install(Vue,token,opt,cb) {
  //判断是否使用mock数据
  if (opt.mock) {
    mockConfig(axios);
  }
  //创建axios实例
  const instance = axios.create({
    baseURL: eapiUrl,
    timeout: 10000, // 超时
    withCredentials: true,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Token: token,
      ...addCookiesToRequestHeaders(),
      ...addQueryToRequestHeaders(),
    },
    transformRequest: [
      (data,header) => {
        if (header['Content-Type'] === 'multipart/form-data') {
          return data;
        }
        if (header['Content-Type'] === 'application/json') {
          return JSON.stringify(data);
        }
        return qs.stringify(data);
      }
    ],
    transformResponse: [
      data => {
        return data;
      }
    ]
  });

  // 添加请求拦截器
  instance.interceptors.request.use(
    function (config) {
      // header 头后补token
      const sToken = Store.store.getters.token
      if (!config.headers.Token && sToken) {
        config.headers['Token'] = sToken
      }
      // 显示 loading
      if (!config.noLoading) {
        try {
          HllUI.$hll.showLoading({
            cover: 2,
            delay: 1000
          });
        } catch (error) {
          
        }
      }
      myConsole?.log?.('接口HOST', config.baseURL)
      const su = createSu();
      const traceId = generateTraceId();
      if (config.url.indexOf('?') !== -1) {
        config.url = `${config.url}&_su=${su}&_traceId=${traceId}`
      } else {
        config.url = `${config.url}?_su=${su}&_traceId=${traceId}`
      }
      if (!config.url.includes('eapi') && !config.url.includes('http')) {
        config.url = `${eapiUrl}${config.url}`
      }
      if (config.url.includes('euc')) {
        config?.data?.data && (config.data.data.su = su)
      }
      myConsole?.log?.('接口URL', config.url)
      // city接口添加验签字段
      if (tools.getInterfaceName(config.url) === 'new_city_info') {
        let params = {
          _su: su,
          _m: tools.getInterfaceName(config.url),
          ...config.params,
          ...config.data
        };
        let commonParam = tools.getCommonParams(params,config.method.toUpperCase());
        Object.keys(commonParam).forEach(key => {
          config.url += `&${key}=${encodeURIComponent(commonParam[key])}`;
        });
      }
      // id重构前端处理长整型数据：以text格式接收数据，在响应拦截里面进一步处理
      if (!config.responseType || config.responseType === 'json') {
        config.responseType = 'text';
      }
      return config;
    },
    function (error) {
      // 对请求错误做些什么
      try {
        HllUI.$hll.hideLoading(); // 清除loading
      } catch (error) {}
      return Promise.reject(error);
    }
  );

  // 添加响应拦截器
  instance.interceptors.response.use(
    function (response) {
      // ID重构 长整形转为字符串
      if (response.config.responseType === 'text') {
        response.data = tools.parseResponse(response.data);
      }
      /**
       * 对响应数据做点什么
       * token失效，通知客户端去登录
       * noLogin 自定义不跳转
       */
      if (response.data.ret === 10003 && !response.config.noLogin) {
        toLoginPageAction();
      }

      if (!response.config.noLoading) {
        HllUI.$hll.hideLoading(); // 清除loading
      }
      return response;
    },
    function (error) {
      try {
        HllUI.$hll.hideLoading(); // 清除loading
      } catch (error) {}
      return Promise.reject(error);
    }
  );

  //添加全局方法
  Vue.prototype.$axios = instance;

  // 调用回调
  cb && typeof cb == "function" && cb(instance)
}

export default {
  install
};
