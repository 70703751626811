// 按需加载模块
const offlineRemittance = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/offline-remittance/offline-remittance.vue'));
    },
    'offline-remittance'
  );
};

const offlineRemittanceB = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/offline-remittance/offline-remittance-b.vue'));
    },
    'offline-remittance-b'
  );
};

const offlineRemittanceNew = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/offline-remittance/offline-remittance-new.vue'));
    },
    'offline-remittance'
  );
};

const fillEpName = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/offline-remittance/fill-ep-name'));
    },
    'offline-remittance'
  );
};

const remittanceRecord = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/offline-remittance/remittance-record'));
    },
    'offline-remittance'
  );
};

const remittanceDetail = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/offline-remittance/remittance-detail'));
    },
    'offline-remittance'
  );
};

// 前端路由
export default [
  {
    path: '/offline-remittance',
    component: offlineRemittance,
    meta: { title: '线下汇款' }
  },
  {
    path: '/offline-remittance-b',
    name: 'offlineRemittanceB',
    component: offlineRemittanceB,
    meta: { title: '线下汇款' }
  },
  {
    path: '/offline-remittance-new',
    name: 'offlineRemittanceNew',
    component: offlineRemittanceNew,
    meta: { title: '充值中心' }
  },
  {
    path: '/fill-ep-name',
    name: 'fillEpName',
    component: fillEpName,
    meta: { title: '充值中心' }
  },
  {
    path: '/remittance-record',
    name: 'remittanceRecord',
    component: remittanceRecord,
    meta: { title: '对公汇款记录' }
  },
  {
    path: '/remittance-detail',
    name: 'remittanceDetail',
    component: remittanceDetail,
    meta: { title: '对公汇款详情' }
  }
];
