// 订单到付跳转页
const ArrivePay = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/arrive-pay/index.vue'));
    },
    'ArrivePay',
  );
};
export default [
  {
    path: '/arrive-pay',
    component: ArrivePay,
    meta: {
      title: '订单到付',
    },
  },
];
