/*
 * @Descripttion: 设置配置文件
 * @Author: jungle.huang
 * @Date: 2020-06-12 20:58:23
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-05-21 10:20:54
 */

// webapck代理域名、验签加固时加密域名
var hostname = 'https://eappweb-pre.huolala.cn';

module.exports = {
  hostname
};
