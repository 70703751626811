<template>
  <div id="app">
    <keep-alive :include="keepAliveComponents" :exclude="['receipt-detail', 'receipt-list', 'receipt-pdf']">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import {tools} from './tools/index'
  import {isShowSensorsLog} from './config.js'
  import { getQueryString, isEapp, isWxa, isIos, getQueryAll } from '@hll/eapp-utils';
  import { onPerformance, getLCPDetail } from '@hll/page-metrics-log';
  import { myConsole } from '@/services/watch-dog';
  import { getEpFrom } from '@/tools';

  // 企业微信小程序ua标识
  export const eMiniprogramUa = 'EP_WXA';

  export default {
    name: 'app',
    data() {
      return {
        transitionName: '',
        platformType: 'web',
      }
    },
    computed: {
      ...mapGetters(['uiVersion']),
      keepAliveComponents() {
        return this.$store.state.keepAlive.components
      }
    },
    watch: {
      // 处理 public.scss 的动态变量
      uiVersion: {
        immediate: true,
        handler(newValue) {
          const root = document.documentElement;
          if (newValue === 1) {
            const primaryColor = window.getComputedStyle(root, null).getPropertyValue('--economic-color-active');
            const primaryColorTenPct = window.getComputedStyle(root, null).getPropertyValue('--economic-color-active-ten-pct');
            root.style.setProperty('--primary-color', primaryColor)
            root.style.setProperty('--primary-color-ten-pct', primaryColorTenPct)
          } else {
            root.style.removeProperty('--primary-color');
            root.style.removeProperty('--primary-color-ten-pct');
          }
        }
      }
    },
    mounted() {
      this.commonInit();
      this.andriodInputBug();
      this.setSaCount();
      this.getBillboardConfig();
      this.getEpVesion();
      this.initMerticsLog();
      this.saveQueryToStore();
    },
    methods: {
      ...mapMutations(['setQueryParams']),
      // 将url携带的参数保存到store
      saveQueryToStore() {
        try {
          const querys = getQueryAll();
          this.setQueryParams(querys || {});
        } catch (e) {
          console.log('保存query参数异常', e);
          this.setQueryParams({});
        }
      },
      /** 采集页面性能参数 */
      initMerticsLog() {
        try {
          onPerformance({
            onReport: (metrics) => {
              myConsole.log('onPerformance', metrics);
            },
          });
          getLCPDetail((lcp, _, renderTime) => {
            myConsole.log('getLCPDetail', lcp, renderTime);
          });
          // eslint-disable-next-line no-empty
        } catch {}
      },
      // 获取顶部悬浮窗配置
      getBillboardConfig() {
        this.$axios
          .get(this.$config.eapiUrl + '/index.php?_m=get_order_share_suspension')
          .then(({ data: { ret, data, msg } }) => {
            if (ret != 0) {
              this.$hll.toast({
                message: msg || '获取悬浮框配置失败',
                iconClass: 'icon_font icon_warning',
              });
            } else {
              try {
                const config = JSON.parse(data.suspensionConfig);
                this.$store.commit('updateBillboard', config);
              } catch (e) {
                console.error(e);
              }
            }
          });
      },
      //解决安卓点击input，弹出键盘挡住input的问题
      andriodInputBug() {
        if (/Android/gi.test(navigator.userAgent)) {
          window.addEventListener('resize', function () {
            if (document.activeElement.tagName == 'INPUT' || document.activeElement.tagName == 'TEXTAREA') {
              window.setTimeout(function () {
                document.activeElement.scrollIntoViewIfNeeded();
              }, 0);
            }
          })
        }
      },
      // 引入神策埋点
      async setSaCount() {
        const epFrom = getEpFrom();
        // 神策埋点
        this.sa.init({
          server_url: this.$config.saCountUrl, // 数据接收地址
          is_track_device_id: true, //设置成 true 后， 表示事件属性里面添加一个设备 id 的属性，存贮在 cookie 里面并发送。默认 false，记录但不发送
          show_log: !!getQueryString('showlog') || false, // 打印日志开关。如果写埋点可以去config文件打开下
          heatmap: {
            //是否开启点击图，默认 default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭
            clickmap: 'default',
            //是否开启触达注意力图，默认 default 表示开启，自动采集 $WebStay 事件，可以设置 'not_collect' 表示关闭
            scroll_notice_map: 'default'
          },
        });

        const ua = getQueryString('ua');

      if ((await isWxa()) && ua === eMiniprogramUa) {
        this.platformType = '微信小程序';
      } else if (isEapp()) {
        if (this.isEpHarmony) {
          this.platformType = 'ep_harmony';
        } else {
          this.platformType = isIos() ? 'iOS' : 'Android';
        }
      } else if (epFrom === 'ep_h5') {
        this.platformType = 'ep_h5';
      }

        this.sa.registerPage({
          source: getQueryString('source') || '',
          platform_type: this.platformType,
        });
        const userFid = tools.getCookie('user_fid');
        if (userFid) {
          this.sa.login(userFid);
        }

        this.sa.identify(tools.getCookie('device_id'))
        this.sa.quick('autoTrack'); // 自动采集Web页面浏览情况
      },
      // 获取企业版本
      async getEpVesion() {
        const epVersion = getQueryString('ep_version');
        if (epVersion) {
          this.$store.commit('setEpVersion', Number(epVersion));
          this.$store.commit('setUiVersion', Number(epVersion) === 2 ? 1 : 0);
        }
      },
      commonInit() {
        // 编译H5 缓存关键参数
        const epFrom = getQueryString('ep_from');
        if (epFrom) {
          localStorage.setItem('epFrom', epFrom);
          this.$store.commit('setEpFrom', epFrom);
        };
      }
    },
  }
</script>

<style lang="scss" scoped>
  #app {
    height: 100%;
    -webkit-overflow-scrolling: touch;
  }
</style>
