export default {
  install(Vue) {
    Vue.prototype.$callNativeFunc = function (params) {
      if (app && app.webcall) {
        app.webcall(JSON.stringify(params))
      } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.app) {
        window.webkit.messageHandlers.app.postMessage(JSON.stringify(params));
      }
    }
  }
}