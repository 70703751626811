//state
const state = {
  // 需要缓存的组件 from:to 即从from到to需要缓存from页面 to支持多个 表示一条路径 路径上的点都会被缓存
  relations: {
    paymentAuthSubmit: ['paymentExample'],
    paymentRepaySubmit: ['paymentExample'],
    repaySubmit: ['repayFlow', 'paymentExample'],
    recharge: ['chargeAgreement'],
    paymentApplyDeposit: ['paymentAgreement'],
    paymentApplyCredit: ['paymentAgreement'],
    verifySubmit: ['verifyExample'],
    epLogoutConfirm: ['eplogoutAgreement'],
    billAppeal: ['cnuserPrice'],
    driverContractRegister: ['driverContractSignature'],
    goodsSafeDetail: ['goodsSafeExplain', 'insuranceProtocol'],
  },
  components: ['launch']
};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  setAliveComponents(state, value) {
    state.components = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
