// 限制说明

// 限制说明
const restrict = resolve => {
  require.ensure(
    [],
    () => {
      resolve(require('../../components/restrict/restrict.vue'));
    },
    'report',
  );
};

// 前端路由
export default [
  {
    path: '/restrict',
    component: restrict,
    meta: {
      title: '我的限制说明',
    },
  },
];
