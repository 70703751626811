/**
 * 开发票
 */

// 开票首页
const invoiceIndex = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/invoice/index'))
  }, 'invoice-index')
};

// 按行程开票
const trailIndex = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/invoice/trail/index'))
  }, 'invoice-trail')
};
const invoicing = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/invoice/trail/invoicing'))
  }, 'invoice-trail')
};
const addressList = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/invoice/trail/address-list'))
  }, 'invoice-trail')
};
const enterAddress = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/invoice/trail/enter-address'))
  }, 'invoice-trail')
};

// 开票历史
const historyIndex = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/invoice/history/index'))
  }, 'invoice-history')
};
const invoiceDetail = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/invoice/history/detail'))
  }, 'invoice-history')
};
const rePush = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/invoice/history/re-push'))
  }, 'invoice-history')
};

// 开票规则
const invoiceRule = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/invoice/rule'))
  }, 'invoice-rule')
};


// 开票设置
const invoiceSeting = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/invoice/seting/index'))
  }, 'invoice-seting')
};

// 前端路由
export default [
  {
    path: '/invoice/index',
    component: invoiceIndex,
    meta: {
      title: '开具发票'
    }
  },
  {
    path: '/invoice/trail/index',
    component: trailIndex,
    meta: {
      title: '按行程开票'
    }
  },
  {
    path: '/invoice/trail/invoicing',
    component: invoicing
  },
  {
    path: '/invoice/trail/address-list',
    component: addressList,
    meta: {
      title: '更多地址'
    }
  },
  {
    path: '/invoice/trail/add-address',
    component: enterAddress,
    meta: {
      title: '添加地址'
    }
  },
  {
    path: '/invoice/trail/edit-address',
    component: enterAddress,
    meta: {
      title: '编辑地址'
    }
  },
  {
    path: '/invoice/history/index',
    component: historyIndex,
    meta: {
      title: '开票记录'
    }
  },
  { // 电子和纸质开票详情由参数[invoice_type]控制
    // invoiceType: 'e_invoice' | 'paper_invoice',
    // default: 'e_invoice'
    path: '/invoice/history/detail',
    component: invoiceDetail
  },
  {
    path: '/invoice/history/re-push',
    component: rePush
  },
  {
    path: '/invoice/rule',
    component: invoiceRule,
    meta: {
      title: '开票规则'
    }
  },
  {
    path: '/invoice/seting/index',
    component: invoiceSeting,
    meta: {
      title: '开票设置'
    }
  },
  /********************异常路由处理********************/
  {
    path: '/invoice',
    redirect: '/invoice/index'
  },
  {
    path: '/invoice/trail',
    redirect: '/invoice/trail/index'
  },
  {
    path: '/invoice/history',
    redirect: '/invoice/history/index'
  }
]
