// eph5项目域名列表
export const epHlightH5Origins = [
  'https://ep-light-h5-v-stg.huolala.cn',
  'https://ep-light-h5--stable-1-v-stg.huolala.cn',
  'https://ep-light-h5--stable-2-v-stg.huolala.cn',
  'https://ep-light-h5--stable-3-v-stg.huolala.cn',
  'https://ep-light-h5--stable-4-v-stg.huolala.cn',
  'https://ep-light-h5--stable-5-v-stg.huolala.cn',
  'https://ep-light-h5-v-pre.huolala.cn',
  'https://ep-light-h5--stable-1-v-pre.huolala.cn',
  'https://ep-light-h5--stable-2-v-pre.huolala.cn',
  'https://ep-light-h5--stable-3-v-pre.huolala.cn',
  'https://ep-light-h5--stable-4-v-pre.huolala.cn',
  'https://ep-light-h5--stable-5-v-pre.huolala.cn',
  'https://ep.huolala.cn',
];


// 企业鸿蒙端的标识
export const EP_HARMONY_OS = 'ep_harmony';

// 小b端H5标识
export const EP_H5_OS = 'ep_h5';