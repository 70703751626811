//按需加载模块
const epCertify = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/verify/submit.vue'))
  }, 'ep-certify')
}
const example = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/verify/example'))
  }, 'ep-certify')
}
const detail = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/verify/detail.vue'))
  }, 'ep-certify')
}

//前端路由
export default [
  {path: '/verify/submit', name: 'verifySubmit', component: epCertify, meta: {title: '企业资质认证'}},
  {path: '/verify/example', name: 'verifyExample', component: example, meta: {title: '查看示例'}},
  {path: '/verify/detail', name: 'verifyDetail', component: detail, meta: {title: '企业资质认证'}},
]
