//按需加载模块
const launch = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/launch'))
  }, 'launch')
}

//前端路由
export default [
  {path: '/launch', component: launch, meta: {title: '货拉拉企业版'}},
]
