const state = {
  licenseWhiteList: [] // 货运资质白名单企业id
}
const getters = {
  isInLicenseWhiteList(state, getters, rootState = {}) {
      const eInformation = (rootState.information || {}).eInformation || {}
      return state.licenseWhiteList.indexOf(eInformation.ep_id) !== -1;
  },
}
const mutations = {
  setLicenseWhiteList(state, licenseWhiteList = []) {
    state.licenseWhiteList = licenseWhiteList
  }
}

export default {
  state,
  getters,
  mutations
}