const apply = reslove => {
  require.ensure(
    [],
    () => {
      reslove(require('../../components/factoring-service/apply.vue'));
    },
    'factoring-service'
  );
};
const certification = reslove => {
  require.ensure(
    [],
    () => {
      reslove(require('../../components/factoring-service/certification.vue'));
    },
    'factoring-service'
  );
};
const applyResult = reslove => {
  require.ensure(
    [],
    () => {
      reslove(require('../../components/factoring-service/apply-result.vue'));
    },
    'factoring-service'
  );
};
const financialAgreement = reslove => {
  require.ensure(
    [],
    () => {
      reslove(require('../../components/factoring-service/financial-agreement.vue'));
    },
    'factoring-service'
  );
};
const paymentAgreement = reslove => {
  require.ensure(
    [],
    () => {
      reslove(require('../../components/factoring-service/payment-agreement.vue'));
    },
    'factoring-service'
  );
};
export default [
  {
    path: '/factoring-service/apply',
    component: apply,
    meta: {
      title: '账期申请'
    }
  },
  {
    path: '/factoring-service/certification',
    component: certification,
    meta: {
      title: '申请资料认证'
    }
  },
  {
    path: '/factoring-service/apply-result',
    component: applyResult,
    meta: {
      title: '开通结果'
    }
  },
  {
    path: '/factoring-service/financial-agreement',
    component: financialAgreement,
    meta: {
      title: '金融信息披露及征信查询授权协议'
    }
  },
  {
    path: '/factoring-service/payment-agreement',
    component: paymentAgreement,
    meta: {
      title: '货拉拉企业版账期协议'
    }
  }
];
