// 账单申诉
const billAppeal = resolve => {
  require.ensure([], () => {
    resolve(require('../../components/bill-appeal/bill-appeal.vue'))
  }, 'billAppeal')
};

// 前端路由
export default [
  {
    path: '/bill-appeal/:uuid',
    name: 'billAppeal',
    component: billAppeal,
    meta: {title: '费用有疑问'}
  }
]
